<template>
  <div class="tracking">
    <h2>View track</h2>

    <div v-if="tracking?.courier_geo_outdated" class="tracking__driver-error">
      <h3>The geo-data has stopped coming from the driver.</h3>
      <span>The map shows the last known position.</span>
    </div>

    <div
      v-if="tracking?.courier_geo_unavailable"
      class="tracking__driver-error"
    >
      <h3>No driver location information</h3>
    </div>

    <div class="tracking__map">
      <div ref="map" class="map"></div>

      <div class="tracking__actions">
        <button class="button button--white" @click="refresh">
          <img src="@/assets/icons/refresh.svg" alt="" />
        </button>
        <button class="button button--white" @click="setCenter">
          <img src="@/assets/icons/location-2.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { Loader } from "@googlemaps/js-api-loader";
import moment from "moment";
import { mapState } from "vuex";
import { toRaw } from "vue";
import RedIcon from "@/assets/icons/markers/red.svg";
import ArrivedIcon from "@/assets/icons/markers/arrived.svg";
import DriverIcon from "@/assets/icons/markers/driver.svg";
import DriverLostIcon from "@/assets/icons/markers/driver-lost.svg";
import BlueIcon from "@/assets/icons/markers/blue.svg";
import FinishIcon from "@/assets/icons/markers/finish.svg";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loader: null,
      map: null,
      directionsService: null,
      directionsRenderer: null,
      markers: [],
      courierMarker: null,
    };
  },

  computed: {
    ...mapState({
      order: (state) => state.orders.orderDetails,
    }),
    tracking() {
      return this.order?.route?.tracking ?? null;
    },
    points() {
      return this.order?.route?.tracking?.map_points ?? [];
    },
    originAddress() {
      return this.points?.find((i) => i.type === "departure");
    },
    destinationAddress() {
      return this.points?.find((i) => i.type === "finish_arrival");
    },
    driver() {
      return this.points?.find((i) => i.type === "courier");
    },
  },

  watch: {
    map: {
      handler(v) {
        if (v) {
          this.calculateAndDisplayRoute();

          this.points?.forEach((point) => {
            let icon;
            let iconSize = new google.maps.Size(20, 20);
            if (point.type === "departure") icon = RedIcon;
            if (point.arrived && point.type === "intermediate_arrival")
              icon = ArrivedIcon;
            if (point.type === "courier"){
            icon = this.tracking?.courier_geo_outdated ? DriverLostIcon : DriverIcon;
            iconSize = new google.maps.Size(30, 30);
            }
            if (!point.arrived && point.type === "intermediate_arrival")
              icon = BlueIcon;
            if (point.type === "finish_arrival") icon = FinishIcon;

            const mark = new google.maps.Marker({
              position: new google.maps.LatLng(point.lat, point.lon),
              map: this.map,
              icon: {
              url: icon,
              scaledSize: iconSize,
            },
            });

            if (point.type === "courier") this.courierMarker = mark;

            if (
              !["courier", "departure"].includes(point.type) &&
              !point.arrived
            ) {
              const text = this.getTime(point.estimated_time_of_arrival);
              this.myToolTip(mark, text);
            }
          });
        }
      },
    },

    driver: {
      deep: true,
      immediate: true,
      handler(v) {
        if (v?.lat && v?.lon && this.map) {
          if (this.courierMarker) {
            const icon = this.tracking?.courier_geo_outdated ? DriverLostIcon : DriverIcon;
            const iconSize = new google.maps.Size(30, 30);
            this.courierMarker.setIcon({
              url: icon,
              scaledSize: iconSize,
            });

            toRaw(this.courierMarker)?.setMap(this.map);
          }
          this.calculateAndDisplayRoute();
          this.courierMarker.setPosition(new google.maps.LatLng(v?.lat, v.lon));
        } else {
          toRaw(this.courierMarker)?.setMap(null);
        }
      },
    },
  },

  methods: {
    async initMap() {
      await this.loader.load();

      this.map = new google.maps.Map(this.$refs.map, {
        zoom: 8,
        center: { lat: 40.771, lng: -73.974 },
        disableDefaultUI: true,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            stylers: [{ visibility: "off" }],
          },
        ],
      });

      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer({
        map: this.map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: "#00A8C3",
        },
      });
    },

    calculateAndDisplayRoute() {
      if (!this.destinationAddress?.address || !this.originAddress?.address)
        return;

      this.directionsService
        ?.route({
          origin: new google.maps.LatLng(
            this.originAddress.lat,
            this.originAddress.lon
          ),
          destination: new google.maps.LatLng(
            this.destinationAddress.lat,
            this.destinationAddress.lon
          ),
          waypoints: this.points
            .filter(
              (point) =>
                point.type === "intermediate_arrival" ||
                point.type === "courier"
            )
            .map((point) => {
              return {
                location: new google.maps.LatLng(point.lat, point.lon),
                stopover: true,
              };
            }),
          optimizeWaypoints: false,
          travelMode: google.maps.TravelMode.DRIVING,
        })
        .then((result) => {
          this.directionsRenderer.setDirections(result);
        })
        .catch((e) => {
          this.$notify({
            text: "Directions request failed due to " + e,
            type: "error",
          });
        });
    },

    setCenter() {
      const driver = this.points?.find((i) => i.type === "courier");
      this.map.setCenter(new google.maps.LatLng(driver.lat, driver.lon));
    },

    getTime(date) {
      return `~ ${moment.utc(date).local().format("hh:mm A")}`;
    },

    myToolTip(mark, text) {
      const infoWindow = new google.maps.InfoWindow({
        pixelOffset: new google.maps.Size(40, 15),
      });
      google.maps.event.addListener(mark, "mouseover", function () {
        infoWindow.setContent('<h4 class="custom-info">' + text + "</h4>");
        infoWindow.open(this.map, mark);
      });
      google.maps.event.addListener(mark, "mouseout", function () {
        infoWindow.close();
      });
    },
    async refresh() {
      await this.data.callback();
    },
  },

  created() {
    this.loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAP_KEY,
      libraries: ["places"],
    });
    this.initMap();
  },
};
</script>

<style lang="scss" scoped>
.tracking {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 40px;

  h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
  }

  &__driver-error {
    color: #da615c;
    h3 {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__map {
    position: relative;
  }

  &__actions {
    position: absolute;
    right: 12px;
    bottom: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .map {
    width: 684px;
    height: 439px;
    box-shadow: 0px 12px 40px -10px rgba(15, 15, 15, 0.07);
    border-radius: 16px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    border-radius: 50%;
    border: 1px solid #f5f5f5;
    box-shadow: 0px 12px 40px -10px rgba(49, 62, 86, 0.1);
    img {
      width: 22px;
      height: 22px;
    }
  }
}
</style>

<style lang="scss">
.gm-style {
  .gm-ui-hover-effect {
    display: none !important;
  }
  .gm-style-iw-tc::after {
    height: 0 !important;
  }

  .gm-style-iw-d,
  .gm-style-iw {
    overflow: hidden !important;
    padding: 0 !important;
  }

  .gm-style-iw-c {
    border-radius: 16px !important;
  }

  .custom-info {
    padding: 10px 12px !important;
    background-color: #37352f;
    color: #fff;
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
  }
}
</style>
