import axios from "axios";
import store from "@/store";
import { v4 as uuidv4 } from "uuid";
import { notify } from "@kyvg/vue3-notification";

const config = {
  headers: { "content-type": "application/json" },
};
let refreshTokenPromise;

const getRefreshToken = async () => {
  const { data } = await store.dispatch("auth/refresh");
  store.commit("auth/setToken", data);
  return data;
};

export class RequestService {
  constructor() {
    this.call = axios.create(config);

    this.call.interceptors.request.use((request) => {
      return request;
    });

    this.call.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalConfig = error.config;
        if (window.location.pathname !== "/login" && error.response) {
          if (error.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            if (!refreshTokenPromise) {
              refreshTokenPromise = getRefreshToken().then((token) => {
                refreshTokenPromise = null;
                return token;
              });
            }

            if (error.response.config.url === "/auth/refresh") {
              store.commit("auth/logout");
              window.location.href = `/login`;
              return;
            }

            return refreshTokenPromise.then((token) => {
              error.config.headers["Authorization"] =
                "Bearer " + token.access_token;
              return this.call(error.config);
            });
          }

          if (
            error.response.status === 422 &&
            error.response.config.url === "/auth/refresh"
          ) {
            store.commit("auth/logout");
            window.location.href = `/login`;
          }
        }

        if (
          error.response.status === 422 &&
          error.response.data?.error?.error_name ===
            "order_departure_time_must_be_changed"
        ) {
          notify({
            text: error.response.data?.error?.error_msg,
            type: "error",
          });
        }

        if (
          Array.isArray(
            error.response?.data?.error?.error_payload?.validation_errors
          ) &&
          error.response?.data?.error?.error_payload?.validation_errors.length
        ) {
          let errors =
            error.response?.data?.error?.error_payload?.validation_errors.map(
              (er) => er.msg
            );

          throw {
            status: 422,
            code: "parsedataerror",
            message: errors.join("<br>"),
          };
        } else {
          throw {
            status: error.response.status,
            code: error.response.data.error.error_name,
            message: error.response.data.error.error_msg,
          };
        }
      }
    );
  }

  async sendRest(arg) {
    let {
      method,
      baseURL,
      url,
      params = {},
      data = {},
      headers = {},
      responseType,
    } = arg;

    const token = JSON.parse(localStorage.getItem("access_token"));

    if (token) {
      headers["Authorization"] = "Bearer " + token.access_token;
      headers["x-request-id"] = uuidv4();
    }

    const config = {
      method,
      url,
      baseURL,
      params,
      data,
      headers,
      responseType,
    };

    try {
      const response = await this.call.request(config);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
      // return
    }
  }
}

export default new RequestService();
