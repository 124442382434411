<template>
  <div class="zone-edit">
    <h2>{{ isEdit ? "Edit zone" : "Add zone" }}</h2>

    <div class="zone-edit__content">
      <div class="zone-edit__form-group">
        <form-input
          v-model="form.name"
          label="Name"
          placeholder="Zone name"
          :disabled="isEdit"
          :regexFilter="/[a-zA-Z0-9\-_]/"
          :error="v$.form.name.required.$invalid && v$.form.name.$dirty"
        />
        <form-date
          label="Expires At"
          placeholder="Expires at"
          v-model="form.expiresAt"
          isDisableDates
        />
        <form-textarea
          class="zone-edit__form-textarea"
          v-model="form.description"
          placeholder="Description"
        />
      </div>

      <div class="zone-edit__form-customers">
        <h3>Associate with customers</h3>
        <div class="zone-edit__form-customers-add">
          <form-phone
            v-model="newCustomerPhone"
            label="Enter the customer’s  phone number"
            placeholder="Enter the customer’s  phone number"
          />
          <button
            class="button button--primary button--bigger"
            :disabled="newCustomerPhone.length !== 10"
            @click="addCustomerPhone"
          >
            Add
          </button>
        </div>

        <div v-if="isEdit" class="zone-edit__form-customers-phones">
          <p
            class="phone-item"
            v-for="(customer, idx) in data?.zone?.associated_customers"
            :key="idx"
          >
            <span>{{ formatPhoneNumber(customer.username) }}</span>
          </p>
        </div>

        <div
          v-if="customersPhones.length"
          class="zone-edit__form-customers-phones"
        >
          <p
            class="phone-item"
            v-for="(phone, idx) in customersPhones"
            :key="idx"
          >
            <span>{{ formatPhoneNumber(phone) }}</span>
            <img
              @click="deleteCustomerPhone(idx)"
              src="@/assets/icons/close-circle.svg"
            />
          </p>
        </div>
      </div>

      <div class="zone-edit__form-drivers">
        <h3>Associate with drivers</h3>
        <div class="zone-edit__form-drivers-add">
          <form-phone
            v-model="newDriverPhone"
            label="Enter the customer’s  phone number"
            placeholder="Enter the customer’s  phone number"
          />
          <button
            class="button button--primary button--bigger"
            :disabled="newDriverPhone.length !== 10"
            @click="addDriverPhone"
          >
            Add
          </button>
        </div>

        <div v-if="isEdit" class="zone-edit__form-drivers-phones">
          <p
            class="phone-item"
            v-for="(courier, idx) in data?.zone?.associated_couriers"
            :key="idx"
          >
            <span>{{ formatPhoneNumber(courier.username) }}</span>
          </p>
        </div>

        <div v-if="driversPhones.length" class="zone-edit__form-drivers-phones">
          <p
            class="phone-item"
            v-for="(phone, idx) in driversPhones"
            :key="idx"
          >
            <span>{{ formatPhoneNumber(phone) }}</span>
            <img
              @click="deleteDriverPhone(idx)"
              src="@/assets/icons/close-circle.svg"
            />
          </p>
        </div>
      </div>
    </div>

    <div class="zone-edit__footer">
      <button
        class="button button--light button--bigger"
        @click="$emit('close')"
      >
        Cancel
      </button>
      <button
        class="button button--primary button--bigger"
        @click="submitHandler"
      >
        {{ isEdit ? "Edit" : "Create" }}
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { formatPhoneNumber } from "@/helpers/phoneFormat";
import { displayDate, setDateWithTimezone } from "@/helpers/timezoneDateTime";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import FormInput from "@/components/form/FormInput.vue";
import FormDate from "@/components/form/FormDate.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import FormPhone from "@/components/form/FormPhone.vue";
export default {
  components: { FormDate, FormInput, FormTextarea, FormPhone },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        name: "",
        expiresAt: null,
        description: "",
      },
      newCustomerPhone: "",
      newDriverPhone: "",
      customersPhones: [],
      driversPhones: [],
    };
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true, $autoDirty: true }) }),
  validations() {
    return {
      form: {
        name: {
          required,
        },
      },
    };
  },

  computed: {
    isEdit() {
      return Boolean(this.$route.params?.id);
    },
  },

  watch: {
    "data.zone": {
      deep: true,
      immediate: true,
      handler(v) {
        if (v && this.isEdit) {
          this.form = {
            name: v.name,
            expiresAt: v.expires_at ? displayDate(v.expires_at) : null,
            description: v.description,
          };
        }
      },
    },
  },

  methods: {
    formatPhoneNumber,
    addCustomerPhone() {
      this.customersPhones = [
        ...this.customersPhones,
        `1${this.newCustomerPhone}`,
      ];

      this.newCustomerPhone = "";
    },
    deleteCustomerPhone(idx) {
      this.customersPhones = this.customersPhones.filter(
        (_, index) => index !== idx
      );
    },
    addDriverPhone() {
      this.driversPhones = [...this.driversPhones, `1${this.newDriverPhone}`];

      this.newDriverPhone = "";
    },
    deleteDriverPhone(idx) {
      this.driversPhones = this.driversPhones.filter(
        (_, index) => index !== idx
      );
    },
    async submitHandler() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      let form;

      const expires_at = this.form.expiresAt
        ? setDateWithTimezone(this.form.expiresAt)
        : null;
      if (this.isEdit) {
        form = {
          description: this.form.description,
          expires_at,
          new_associated_customers: [...this.customersPhones],
          new_associated_couriers: [...this.driversPhones],
        };
      } else {
        form = {
          name: this.form.name,
          description: this.form.description,
          expires_at,
          associated_customers: [...this.customersPhones],
          associated_couriers: [...this.driversPhones],
        };
      }

      await this.data.callback({
        closeModal: () => this.$emit("close"),
        form,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.zone-edit {
  width: 764px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  color: #37352f;
  box-sizing: border-box;

  h2 {
    padding: 40px 40px 0 40px;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 40px;
  }

  &__form {
    &-group {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
    }
    &-textarea {
      grid-column: span 2;
    }

    &-customers,
    &-drivers {
      display: flex;
      flex-direction: column;
      gap: 12px;
      transition: all 0.3s;

      h3 {
        color: #6e6d69;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
      &-add {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
        .button {
          width: fit-content;
        }
      }
      &-phones {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
      }

      .phone-item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 4px 8px 4px 12px;
        border-radius: 50px;
        background: #f5f5f5;
        color: #37352f;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;

        img {
          cursor: pointer;
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding: 20px 40px 40px 40px;
    border-top: 1px solid #f5f5f5;
    .button {
      width: 160px;
      &--light {
        color: #00A8C3;
      }
    }
  }
}

:deep(.form-date__value) {
  input {
    width: 100%;
  }
}
</style>
