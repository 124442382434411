import RequestService from "@/api";
const BASE_URL = process.env.VUE_APP_BASE_URL;

const initialState = {
  addModalOpened: false,
  enabled: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  disabled: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  limit: 15,
  isLoading: false,
};

export const state = () => initialState;

export const getters = {};

export const mutations = {
  SWITCH_ADD_MODAL_OPENED(state) {
    state.addModalOpened = !state.addModalOpened;
  },
  SET_COUPONS(state, { type, items, pages, itemsCount, page }) {
    state[type] = {
      items: items,
      pages: pages,
      itemsCount: itemsCount,
      page: page,
    };
  },
  SET_PAGE(state, payload) {
    state[payload.type] = payload.page;
  },
  SWITCH_IS_LOADING(state) {
    state.isLoading = !state.isLoading;
  },
};

export const actions = {
  async createCoupon(context, params) {
    await RequestService.sendRest({
      method: "post",
      baseURL: BASE_URL,
      url: `/cooperator/coupons`,
      data: params,
    });
  },
  async changeCouponStatus(context, { couponId, enabled }) {
    await RequestService.sendRest({
      method: "patch",
      baseURL: BASE_URL,
      url: `/cooperator/coupons/${couponId}/enable`,
      data: {
        enabled,
      },
    });
  },
  async fetchCoupons({ state, commit }, { page, enabled }) {
    let result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/coupons/list`,
      params: {
        page_size: state.limit,
        page_number: page,
        is_enabled: enabled,
      },
    });

    const type = enabled ? "enabled" : "disabled";

    commit("SET_COUPONS", {
      type,
      items: [...state[type].items, ...result.data.items],
      pages: result.data.pagination.total_pages,
      itemsCount: result.data.pagination.total_items,
      page: page,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
