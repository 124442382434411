import RequestService from "@/api";
const BASE_URL = process.env.VUE_APP_BASE_URL;

const initialState = {
  zoneDetail: null,
  zones: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  limit: 20,
};

export const state = () => initialState;

export const mutations = {
  SET_ZONE_DETAIL(state, data) {
    state.zoneDetail = data;
  },
  SET_ZONES(state, { items, pages, itemsCount, page }) {
    state.zones = {
      items: items,
      pages: pages,
      itemsCount: itemsCount,
      page: page,
    };
  },
};

export const actions = {
  async createZone({ state, commit }, form) {
    await RequestService.sendRest({
      method: "post",
      baseURL: BASE_URL,
      url: `/cooperator/app-zone`,
      data: form,
    });
  },
  async editZone({ state, commit }, { form, zoneName }) {
    await RequestService.sendRest({
      method: "put",
      baseURL: BASE_URL,
      url: `/cooperator/app-zone/${zoneName}`,
      data: form,
    });
  },
  async deleteZone({ state, commit }, { zoneName }) {
    await RequestService.sendRest({
      method: "delete",
      baseURL: BASE_URL,
      url: `/cooperator/app-zone/${zoneName}`,
    });
  },
  async fetchZoneDetail({ state, commit }, { zoneName }) {
    const result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/app-zone/${zoneName}/fully`,
    });
    commit("SET_ZONE_DETAIL", result.data);
  },
  async fetchZones({ state, commit }, { page, query }) {
    const result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/app-zone/list`,
      params: {
        page_size: state.limit,
        page_number: page,
        query,
      },
    });
    if (page === 1) {
      commit("SET_ZONES", {
        items: result.data.app_zones,
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: page,
      });
    } else {
      commit("SET_ZONES", {
        items: [...state["customers"].items, ...result.data.app_zones],
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: page,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
