<template>
  <div class="layout">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
}
</style>
