<template>
  <transition name="custom-modal">
    <div v-if="component" class="custom-modal" @click="closeModal">
      <div class="custom-modal__content" @click.stop>
        <component
          :is="component"
          :data="data"
          @close="closeModal"
          @changeAction="this.data.callback"
        />
        <button
          v-if="!data.hideCloseBtn"
          class="custom-modal__close-button"
          @click="closeModal"
        >
          <img
            class="custom-modal__close-button-icon"
            src="@/assets/icons/close-gray.svg"
            alt="Close"
          />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import eventBus from "@/helpers/eventBus";

import ModalActionSubmit from "./ModalActionSubmit.vue";
import ModalCourierRequestDecline from "./ModalCourierRequestDecline.vue";
import ModalCouponAdd from "./ModalCouponAdd.vue";
import ModalBonusActivate from "./ModalBonusActivate.vue";
import ModalBonusAdd from "./ModalBonusAdd.vue";
import ModalCourierCheckr from "./ModalCourierCheckr.vue";
import ModalOrderDropDriver from "./ModalOrderDropDriver.vue";
import ModalNotificationAdd from "./ModalNotificationAdd.vue";
import ModalTrackRouteMap from "./ModalTrackRouteMap.vue";
import ModalAvailableDriversMap from "./ModalAvailableDriversMap.vue";
import ModalApplicationZoneDelete from "./ModalApplicationZoneDelete.vue";
import ModalApplicarionZoneEdit from "./ModalApplicarionZoneEdit.vue";
import ModalBlockDrivers from "./ModalBlockDrivers.vue";

export default {
  name: "ModalGlobal",
  components: {
    ModalActionSubmit,
    ModalCourierRequestDecline,
    ModalCouponAdd,
    ModalBonusActivate,
    ModalBonusAdd,
    ModalCourierCheckr,
    ModalOrderDropDriver,
    ModalNotificationAdd,
    ModalTrackRouteMap,
    ModalAvailableDriversMap,
    ModalApplicationZoneDelete,
    ModalApplicarionZoneEdit,
    ModalBlockDrivers,
  },
  data() {
    return {
      component: null,
      data: null,
      hideCloseBtn: false,
    };
  },
  created() {
    eventBus.on("modal", (passed) => {
      this.hideCloseBtn = passed.hideCloseBtn;
      this.component = passed.component;
      this.data = passed.data;
    });
  },
  watch: {
    component() {
      if (this.component !== null) {
        document.body.style.overflow = "hidden";
        window.addEventListener("keydown", this.handleEscape);
      } else {
        document.body.style.overflow = "";
        window.removeEventListener("keydown", this.handleEscape);
      }
    },
  },
  methods: {
    closeModal() {
      this.component = null;
      this.data = null;
      document.body.style.overflow = "";
    },
    handleEscape(evt) {
      if (this.component !== null && evt.key === "Escape") {
        this.closeModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/silence/scroll-bar-decorative";

.custom-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 110;
  overflow-y: auto;
  @extend %scroll-bar-decorative;
  padding: 8 * 2.5;
  background-color: rgba(11, 11, 11, 0.4);

  @media (max-width: 768px) {
    padding: 20px 10px;
  }

  // Elements
  .custom-modal__content {
    margin: auto;
    position: relative;
    background-color: #fff;
    color: #000;

    transition: all 0.2s;
    max-width: 100%;
    border-radius: 2 * 0.5;
    border-radius: 16px;
    box-shadow: 0px 12px 40px -10px rgba(15, 15, 15, 0.07);

    min-width: 100%;

    @media (min-width: 768px) {
      min-width: 400px;
      padding: 8 * 4;
    }
  }

  .custom-modal__close-button {
    position: absolute;
    z-index: 1000;
    top: 24px;
    right: 24px;
    display: flex;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
    padding: 8 * 0.5;
    cursor: pointer;

    &-icon {
      width: 18px;
      transition: 0.3s ease opacity 0s;
    }

    @media (min-width: 768px) {
      &:hover {
        &-icon {
          opacity: 0.6;
        }
      }
    }

    @media (min-width: 768px) {
      top: 8 * 1.5;
      right: 8 * 1.5;
    }
  }

  // States
  &-enter-active,
  &-leave-active {
    transition: opacity 0.3s;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;

    .custom-modal__content {
      opacity: 0;
      transform: translateY(40%);
    }
  }
}
</style>
