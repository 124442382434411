<template>
  <div class="form-date">
    <div
      class="form-date__inner"
      :class="{
        'form-date__inner--disabled': disabled,
        'form-date__inner--error': error,
      }"
    >
      <label>{{ label }}</label>

      <div
        class="form-date__value"
        :class="{ 'date-picker__value--error': error }"
      >
        <v-date-picker
          v-model="selected"
          :is-range="isRange"
          :min-date="disabledDates"
        >
          <template #default="{ inputEvents }">
            <input
              class="w-100"
              :placeholder="placeholder"
              :value="formatedDate"
              v-on="isRange ? inputEvents.start : inputEvents"
            />
          </template>
        </v-date-picker>
        <img src="@/assets/icons/calendar.svg" />
      </div>
    </div>
    <span v-if="error" class="date-picker__error-text">Field is required</span>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  props: {
    label: {
      type: String,
      required: false,
    },
    modelValue: {
      type: [Date, Object, String],
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: "Field is required",
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    isDisableDates: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: new Date(),
    };
  },
  computed: {
    formatedDate() {
      if (!this.selected) return "";
      if (this.isRange) {
        const even = (date) => Boolean(date) === false;
        return Object.values(this.selected).some(even)
          ? ""
          : `${moment(Object.values(this.selected)[0]).format(
              "D MMM"
            )} - ${moment(Object.values(this.selected)[1]).format("D MMM")}`;
      }
      return moment(this.selected).format("MMMM D, YYYY");
    },

    disabledDates() {
      const day = new Date();
      // if (moment().format("HH:mm") > "23:59") {
      //   const tomorrow = new Date();
      //   day.setDate(tomorrow.getDate() + 1);
      // }

      return this.isDisableDates ? day : undefined;
    },
  },
  watch: {
    selected: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },
    },

    modelValue: {
      handler(value) {
        this.selected = value;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    moment,
  },
};
</script>

<style scoped lang="scss">
.form-date {
  &__inner {
    border: 1.5px solid #ebebea;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 6.5px 10px 6.5px 20px;
    position: relative;

    &--error {
      border-color: #da615c;
    }

    &--disabled {
      background: #f5f5f5;
    }
  }

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6e6d69;
  }

  &__value {
    display: flex;
    justify-content: space-between;

    div {
      width: 100%;
    }
  }

  ::v-deep .v3dp__input_wrapper input {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #37352f;
    border: none;
    width: 100%;
    outline: none;
  }

  ::v-deep .v3dp__datepicker {
    width: 100%;
  }

  input {
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #37352f;
    padding: 0;
    outline: none;
  }
}
.date-picker {
  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6e6d69;
  }

  &__error-text {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #da615c;
    margin-top: 6px;
  }

  input {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #37352f;
    border: none;
    outline: none;
    width: 100%;
  }

  img {
    position: relative;
    z-index: 10;
  }
}
</style>
