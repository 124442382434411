<template>
  <div v-if="!isProfileLoading" id="app">
    <router-view></router-view>
    <modal-global></modal-global>
    <notifications pauseOnHover style="top: 20px; right: 20px" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModalGlobal from "@/components/modal/ModalGlobal";

export default {
  name: "App",
  components: {
    ModalGlobal,
  },
  data() {
    return {
      isProfileLoading: true,
    };
  },
  computed: {
    ...mapState("auth", ["token"]),
    ...mapState("profile", ["profile"]),
  },
  async created() {
    if (this.token) {
      await this.$store.dispatch("profile/fetchProfile");
      if (this.profile?.user?.deactivated) {
        this.$store.commit("auth/logout");
        this.$router.push("/login");
      }
    }
    this.isProfileLoading = false;
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/index";

.notification {
  margin-top: 42px;
  top: 50px;
}
</style>
