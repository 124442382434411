<template>
  <div class="zone-delete">
    <h2>Delete Application Zone?</h2>

    <div class="zone-delete__desc">
      Do you want to delete a Application Zone
      <span class="name">{{ data.name }}</span>
      <span>?</span> <span>All application zone users will be deleted.</span>
    </div>
    <div class="zone-delete__footer">
      <button
        class="button button--light button--bigger"
        @click="$emit('close')"
      >
        Cancel
      </button>
      <button
        class="button button--primary button--bigger"
        @click="submitHandler"
      >
        Delete
      </button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["close"],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async submitHandler() {
      await this.data.callback({
        closeModal: () => this.$emit("close"),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.zone-delete {
  width: 468px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  color: #37352f;

  h2 {
    padding: 40px 40px 0 40px;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
  }

  &__desc {
    padding: 0 40px;
    .name {
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  &__footer {
    display: flex;
    gap: 20px;
    padding: 20px 40px 40px 40px;
    border-top: 1px solid #f5f5f5;
    .button {
      width: 100%;
      &--light {
        color: #00A8C3;
      }
    }
  }
}
</style>
