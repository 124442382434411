import RequestService from "@/api";
const BASE_URL = process.env.VUE_APP_BASE_URL;

export const FETCH_COURIERS = "couriers/fetchCouriers";

export const UPDATE_REQUEST = "couriers/updateRequestStatus";

const initialState = {
  addModalOpened: false,
  selectedCourier: null,
  courierIdFilter: null,
  new: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  in_process: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  approved: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  declined: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  limit: 15,
  isLoading: false,
};

export const state = () => initialState;

export const getters = {};

export const mutations = {
  SWITCH_IS_LOADING(state) {
    state.isLoading = !state.isLoading;
  },
  SET_SELECTED_COURIER(state, payload) {
    state.selectedCourier = payload;
  },
  SET_VERIFY_COURIER_ID_FILTER(state, payload) {
    state.courierIdFilter = payload;
  },
  SET_COURIER_CHECKR(state, payload) {
    if (state.selectedCourier.checkr_check?.status !== payload?.status) {
      state.selectedCourier.checkr_check = payload;
      state.selectedCourier.checkr_check_id = payload.id;
    }
  },
  SET_COURIERS(state, { type, items, pages, itemsCount, page }) {
    state[type] = {
      items: items,
      pages: pages,
      itemsCount: itemsCount,
      page: page,
    };
  },
  SET_PAGE(state, payload) {
    state[payload.type] = payload.page;
  },
};

export const actions = {
  async fetchCouriers({ state, commit }, { page, status, only_my }) {
    let result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/courier/verification/requests`,
      params: {
        page_size: state.limit,
        page_number: page,
        statuses: status,
        only_my,
        courier_id: state.courierIdFilter,
      },
    });

    commit("SET_COURIERS", {
      type: status,
      items: [...state[status].items, ...result.data.requests],
      pages: result.data.pagination.total_pages,
      itemsCount: result.data.pagination.total_items,
      page: page,
    });
  },
  async getCourierById({ commit }, { courier_id }) {
    let { data } = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      // url: `/cooperator/courier/verification/request/${courier_id}/${
      //   full ? "full" : "short"
      // }`,
      url: `/cooperator/courier/verification/request/${courier_id}`,
    });
    console.log(data, 'data')

    commit("SET_SELECTED_COURIER", data);
  },

  async updateRequestStatus({ commit }, { id, type, message }) {
    let { data } = await RequestService.sendRest({
      method: "patch",
      baseURL: BASE_URL,
      url: `/cooperator/courier/verification/request/${id}/${type}`,
      params: {
        message,
      },
    });
    commit("SET_SELECTED_COURIER", data);
  },

  async getCheckrStatus({ state, commit }) {
    let { data } = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/courier/verification/request/${state.selectedCourier.id}/checkr`,
    });
    if (data) commit("SET_COURIER_CHECKR", data);
  },

  async checkrCourierById({ state, commit }, params) {
    let { data } = await RequestService.sendRest({
      method: "post",
      baseURL: BASE_URL,
      url: `/cooperator/courier/verification/request/${state.selectedCourier.id}/checkr/recheck`,
      data: params,
    });

    if (data) {
      commit("SET_COURIER_CHECKR", data);
      return data;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
