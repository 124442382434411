import RequestService from "@/api";
const BASE_URL = process.env.VUE_APP_BASE_URL;

const initialState = {};

export const state = () => initialState;

export const getters = {};

export const mutations = {};

export const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
