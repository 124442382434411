<template>
  <div class="loading">
    <div class="spinning-icon">
      <div class="load-icon"></div>
    </div>
    <p>Loading ...</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.loading {
  width: fit-content;
  display: flex;
  align-items: baseline;
  gap: 10px;
  padding-bottom: 10px;
  margin: 0 auto;
}
@keyframes circle-rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinning-icon {
  width: 20px;
  height: 20px;
}

.load-icon {
  width: 100%;
  height: 100%;
  border: 3px solid #f5f5f5;
  border-radius: 50%;
  position: relative;
  z-index: 5;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 3px solid transparent;
    border-right: 3px solid #2461db;
    border-radius: 50%;
    position: absolute;
    top: -3px;
    left: -3px;
    animation: circle-rotate 0.8s both linear infinite;
  }
}

p {
  color: #c3c2c1;
  font-size: 14px;
  line-height: 20px;
}
</style>
