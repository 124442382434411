<template>
  <div class="notification-add">
    <div class="notification-add__body">
      <div class="notification-add__title">Add notification</div>
      <div class="notification-add__form">
        <div class="notification-add__form-row">
          <toggler v-model="form.to_all" />
          <span>All {{ this.usersType }}</span>
        </div>
        <div
          class="notification-add__errors"
          v-if="v$.form.receiver_ids.$dirty && v$.form.receiver_ids.$invalid"
        >
          Please select at least one driver or all at once
        </div>
        <notifications-drivers-multi-select
          v-if="this.users"
          :disabled="form.to_all"
          :items="users"
          :placeholder="this.usersType"
          @update:selectedValues="handleSelectedValues"
          @load-more="loadMoreItems"
          @search="(v) => (search = v)"
        />
        <div
          class="notification-add__errors"
          v-if="v$.form.receiver_ids.$dirty && v$.form.receiver_ids.$invalid"
        >
          Please select at least one driver or all at once
        </div>
        <form-input
          class="form-input"
          v-model="form.title"
          placeholder="Title"
          label=""
          :error="v$.form.title.required.$invalid && v$.form.title.$dirty"
        ></form-input>
        <form-textarea
          class="form-textarea"
          v-model="form.subtitle"
          placeholder="Message"
          :error="v$.form.subtitle.required.$invalid && v$.form.subtitle.$dirty"
        ></form-textarea>
      </div>
    </div>
    <div class="notification-add__footer">
      <button class="button button--light" @click="$emit('close')">
        Cancel
      </button>
      <button
        class="button button--primary button--bigger"
        @click="send"
        :disabled="loading"
      >
        <div v-if="loading">
          <span
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          ></span>
          Sending...
        </div>
        <span v-else>Send</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import FormInput from "@/components/form/FormInput.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import NotificationsDriversMultiSelect from "@/components/notifications/NotificationsDriversMultiSelect.vue";
import Toggler from "@/components/form/Toggler.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf, maxValue } from "@vuelidate/validators";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    FormInput,
    FormTextarea,
    Toggler,
    NotificationsDriversMultiSelect,
  },
  data() {
    return {
      loading: false,
      form: {
        title: "",
        subtitle: "",
        receiver_ids: [],
        to_all: false,
      },
      search: "",
      routePath: this.$route.path,
    };
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true, $autoDirty: true }) }),
  async mounted() {
    this.fetchUsers();
  },
  watch: {
    search() {
      this.fetchUsersWithFilters({ page: 1, sortBy: "fullname" });
    },
  },
  computed: {
    ...mapState({
      drivers: (state) => state.drivers.all.items,
      driversPage: (state) => state.drivers.all.page,
      driversPages: (state) => state.drivers.all.pages,
      customers: (state) => state.customers.customers.items,
      customersPage: (state) => state.customers.customers.page,
      customersPages: (state) => state.customers.customers.pages,
    }),
    users() {
      if (this.routePath.includes("drivers")) {
        return this.drivers;
      }
      if (this.routePath.includes("customers")) {
        return this.customers;
      }
      return null;
    },
    usersType() {
      if (this.routePath.includes("drivers")) {
        return "Drivers";
      }
      if (this.routePath.includes("customers")) {
        return "Customers";
      }
      return null;
    },
  },
  methods: {
    ...mapActions("drivers", ["fetchDrivers"]),
    ...mapActions("customers", ["fetchCustomers"]),
    async fetchUsers() {
      try {
        if (this.routePath.includes("drivers")) {
          await this.fetchDrivers({ page: 1, sortBy: "fullname", type: "all" });
        }
        if (this.routePath.includes("customers")) {
          await this.fetchCustomers({ page: 1, sortBy: "fullname" });
        }
      } catch (e) {
        console.error(e);
      }
    },
    async send() {
      const form = this.form;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.loading = true;
      await this.data.callback({
        closeModal: () => this.$emit("close"),
        form: {
          ...form,
        },
      });

      this.loading = false;
    },
    async fetchUsersWithFilters(params) {
      this.loading = true;
      try {
        if (this.routePath.includes("drivers")) {
          await this.fetchDrivers({
            ...params,
            query: this.search,
            type: "all",
          });
        }
        if (this.routePath.includes("customers")) {
          await this.fetchCustomers({
            ...params,
            query: this.search,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    handleSelectedValues(value) {
      this.form.receiver_ids = value;
    },
    loadMoreItems() {
      if (this.routePath.includes("drivers")) {
        if (this.driversPages > this.driversPage) {
          this.fetchUsersWithFilters({
            page: this.driversPage + 1,
            sortBy: "fullname",
            type: "all",
          });
        }
      }
      if (this.routePath.includes("customers")) {
        if (this.customersPages > this.customersPage) {
          this.fetchUsersWithFilters({
            page: this.customersPage + 1,
            sortBy: "fullname",
          });
        }
      }
    },
  },
  validations() {
    return {
      form: {
        title: {
          required,
        },
        subtitle: {
          required,
        },
        receiver_ids: {
          required: requiredIf(function () {
            return !this.form.to_all;
          }),
        },
      },
    };
  },
};
</script>

<style scoped lang="scss">
.notification-add {
  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #37352f;
    margin-bottom: 40px;
  }

  &__body {
    padding: 40px 40px 24px 40px;
  }

  &__footer {
    padding: 20px 40px;
    border-top: 1px solid #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // &__cancel {
  //   font-weight: 600;
  //   font-size: 14px;
  //   line-height: 20px;
  //   display: flex;
  //   align-items: center;
  //   color: #6e6d69;
  //   border: none;
  //   background: none;
  //   cursor: pointer;
  // }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-row {
      display: flex;
      align-items: center;
      gap: 12px;
      padding-bottom: 10px;

      span {
        color: #37352f;
      }
    }
  }

  &__errors {
    margin-top: -8px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #da615c;
  }
}

.form-select {
  height: 56px;
  display: flex;
  justify-content: center;
}

::v-deep .input-field > .input-field__inner {
  height: 56px;
  display: flex;
  justify-content: center;
}

::v-deep .form-textarea > .input-field__inner {
  height: 112px;
  display: flex;
  justify-content: center;
}

.button {
  width: 156px;
}
</style>
