import RequestService from "@/api";
const BASE_URL = process.env.VUE_APP_BASE_URL;

const initialState = {
  bonusModalOpened: false,
  courierIdFilter: null,
  bonuses: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  limit: 15,
  isLoading: false,
};

export const state = () => initialState;
export const mutations = {
  SWITCH_BONUS_MODAL_OPENED(state) {
    state.bonusModalOpened = !state.bonusModalOpened;
  },

  SET_BONUS_COURIER_ID_FILTER(state, payload) {
    state.courierIdFilter = payload;
  },

  SET_BONUSES(state, { type, items, pages, itemsCount, page }) {
    state[type] = {
      items: items,
      pages: pages,
      itemsCount: itemsCount,
      page: page,
    };
  },
};

export const actions = {
  async fetchBonuses({ state, commit }, params) {
    const initParams = new URLSearchParams();
    initParams.append("page_size", state.limit);
    initParams.append("page_number", params?.page);

    if (params?.query) {
      initParams.append("query", params.query);
    }

    if (params?.fromTime) {
      initParams.append("from_time", params.fromTime);
    }

    if (params?.toTime) {
      initParams.append("to_time", params.toTime);
    }

    if (params?.statuses?.length) {
      params.statuses.forEach((status) => {
        initParams.append("statuses", status);
      });
    }
    if (state.courierIdFilter)
      initParams.append("courier_id", state.courierIdFilter);

    const result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/courier/bonus/list`,
      params: initParams,
    });

    if (params?.page === 1) {
      commit("SET_BONUSES", {
        type: "bonuses",
        items: result.data.bonuses,
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: params.page,
      });
    } else {
      commit("SET_BONUSES", {
        type: "bonuses",
        items: [...state["bonuses"].items, ...result.data.bonuses],
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: params?.page,
      });
    }
  },

  async changeBonusStatus(_, { bonusId, enabled }) {
    await RequestService.sendRest({
      method: "patch",
      baseURL: BASE_URL,
      url: `/cooperator/courier/bonus/${bonusId}/enable`,
      data: {
        enabled,
      },
    });
  },

  async createBonus(_, params) {
    await RequestService.sendRest({
      method: "post",
      baseURL: BASE_URL,
      url: `/cooperator/courier/bonus`,
      data: params,
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
