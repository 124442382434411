<template>
  <popper
    :arrow="false"
    @open:popper="statusArrow = true"
    @close:popper="statusArrow = false"
  >
    <div
      class="autocomplete"
      :class="{
        'autocomplete--open': statusArrow,
      }"
    >
      <form-input
        v-model="search"
        :label="label"
        :placeholder="placeholder"
        :error="error"
        :errorText="errorText"
        :disabled="disabled"
        @focus="onFocus"
        @input="onInput"
      ></form-input>
      <img
        class="autocomplete__arrow"
        src="@/assets/icons/arrow-down-outline.svg"
        alt=""
      />
    </div>

    <template #content="{ close }">
      <ul v-if="items.length" class="autocomplete__items">
        <li
          class="autocomplete__items-item"
          v-for="item in items"
          :key="item.id"
          @click="onSelect(item, close)"
        >
          <slot :item="item">
            <!-- <span @click="close">{{ item.name }}</span> -->
          </slot>
        </li>
      </ul>

      <div v-else class="autocomplete__items autocomplete__items--empty">
        items not found
      </div>
    </template>
  </popper>
</template>

<script>
// Доработать если нужно
import Popper from "vue3-popper";
import debounce from "lodash/debounce";
import FormInput from "@/components/form/FormInput.vue";
export default {
  components: {
    FormInput,
    Popper,
  },
  emits: ["search", "selected"],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    valueKey: {
      type: String,
      default: "id",
    },
    // modelValue: {
    //   type: [String, Number],
    //   default: "",
    // },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Enter Text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: "Field is required",
    },
  },

  // watch: {
  //   modelValue: {
  //     deep: true,
  //     immediate: true,
  //     handler(v) {
  //       this.search = v;
  //     },
  //   },
  //   search() {},
  // },

  data() {
    return {
      search: "",
      statusArrow: false,
    };
  },

  methods: {
    debounceInput: debounce(function (e) {
      this.$emit("search", e.target.value);
    }, 350),
    onFocus() {
      if (!this.items.length) this.$emit("search", this.search);
    },
    onInput(e) {
      this.debounceInput(e);
    },
    onSelect(item, close) {
      this.search = item[this.valueKey];
      this.$emit("selected", item);
      // setTimeout(() => , 150);
			close()
    },
  },
};
</script>

<style lang="scss" scoped>
.autocomplete {
  position: relative;

  &--open {
    .autocomplete__arrow {
      transform: rotate(180deg);
    }
  }

  &__arrow {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  &__items {
    height: 120px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &--empty {
      align-items: center;
      justify-content: center;
    }

    &-item {
      padding: 4px 8px;
      border-bottom: 1px solid #f5f5f5;
      // cursor: pointer;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      width: 4px;
      background: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      background: #6e6d69;
      border-radius: 12px;
    }
  }
}
:deep(.input-field__inner) {
  padding-right: 40px;
}
:deep(.popper) {
  box-sizing: border-box;
  max-width: 320px;
  width: 100%;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid #f5f5f5;
  background: #fff;
  box-shadow: 0px 12px 40px -10px rgba(15, 15, 15, 0.15);
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #fff;
}
</style>
