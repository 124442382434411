export const ACCESS_TOKEN = "access_token";

export const USER_ID = "user_id";

export const COMPANY_TYPES = {
  INDIVIDUAL: "individual",
  LEGAL: "legal",
};

export const USER_ROLES = {
  ADMIN: "admin",
  MANAGER: "manager",
  COOPERATOR: "cooperator",
};

export const CAR_TYPES = [
  {
    id: 'sedan',
    name: 'Sedan',
    weight: 200,
  },
  {
    id: 'pickup',
    name: 'Pickup',
    weight: 600,
  },
  {
    id: 'van',
    name: 'Van',
    weight: 1000,
  },
  {
    id: 'SUV',
    name: 'SUV',
    weight: 2000,
  },
]