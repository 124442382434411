<template>
  <div class="bonus-add">
    <div class="bonus-add__body">
      <h2>Add bonus</h2>

      <div class="bonus-add__form">
        <form-autocomplete
          label="Driver"
          :placeholder="'Driver'"
          @search="searchUsersDrivers"
          @selected="onSelectDriver"
          :items="drivers"
          :value-key="'fullname'"
          :error="
            v$.form.courier_id.required.$invalid && v$.form.courier_id.$dirty
          "
        >
          <template #default="{ item }">
            <div class="bonus-add__drivers">
              <img :src="item?.avatar?.thumb_url || defaultAvatar" alt="" />
              <span>{{ item.fullname }}</span>
            </div>
          </template>
        </form-autocomplete>

        <form-autocomplete
          label="Order’s Number"
          :placeholder="'Order’s Number'"
          @search="searchDriverOrder"
          @selected="onSelectOrder"
          :items="orders"
          :disabled="!form.courier_id"
          :error="v$.form.order_id.required.$invalid && v$.form.order_id.$dirty"
        >
          <template #default="{ item }">
            <div class="bonus-add__orders">
              <span>{{ `# ${item.id} ${item.title}` }}</span>
            </div>
          </template>
        </form-autocomplete>

        <input-field-modern
          v-model="form.amount"
          class="bonus-add__form-denomination"
          label="Denomination"
          placeholder="Enter denomination"
          currency
          :error="v$.form.amount.required.$invalid && v$.form.amount.$dirty"
        />
        <form-input
          v-model="form.title"
          label="Title"
          placeholder="Your title"
          :error="v$.form.title.required.$invalid && v$.form.title.$dirty"
        ></form-input>
        <form-textarea
          v-model="form.description"
          placeholder="Your comment"
          :error="
            v$.form.description.required.$invalid && v$.form.description.$dirty
          "
        ></form-textarea>
      </div>
    </div>

    <div class="bonus-add__footer">
      <button class="button button--ghost" @click="$emit('close')">
        Cancel
      </button>
      <button class="button button--primary" @click="submitHandler">
        Create
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf, maxValue } from "@vuelidate/validators";

import FormInput from "@/components/form/FormInput.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import FormAutocomplete from "@/components/form/FormAutocomplete.vue";
import InputFieldModern from "@/components/form/InputFieldModern.vue";
import defaultAvatar from "@/assets/icons/user.svg";

export default {
  components: {
    FormInput,
    FormTextarea,
    InputFieldModern,
    FormAutocomplete,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      defaultAvatar,
      form: {
				order_id: null,
				courier_id: null,
        amount: null,
        title: "",
        description: "",
      },
    };
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true, $autoDirty: true }) }),
  validations() {
    return {
      form: {
        amount: {
          required,
          maxValue: maxValue(5000),
        },
        title: {
          required,
        },
        description: {
          required,
        },
        courier_id: {
          required,
        },
        order_id: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapState({
      drivers: (state) => state.drivers.all.items,
      orders: (state) => state.orders.completed.items,
    }),
  },

  methods: {
    ...mapMutations("orders", ["SET_ORDERS", "SET_COURIER_ID_FILTER"]),
    ...mapActions("orders", ["fetchCompletedOrders"]),
    ...mapMutations("drivers", ["SET_DRIVERS"]),
    ...mapActions("drivers", ["fetchDrivers"]),
    async submitHandler() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      await this.data.callback({
        closeModal: () => this.$emit("close"),
        form: { ...this.form, amount: this.form.amount * 100 },
      });
    },

    onSelectDriver(item) {
      this.SET_COURIER_ID_FILTER(item.id);
      this.form.courier_id = item.id;
    },
    onSelectOrder(item) {
      this.form.order_id = item.id;
    },
    async searchUsersDrivers(search) {
      try {
        await this.fetchDrivers({
          page: 1,
          query: search,
          type: "all"
        });
      } catch (err) {
        console.log(err);
      }
    },
    async searchDriverOrder(search) {
      if (!this.form.courier_id) return;
      try {
        await this.fetchCompletedOrders({ page: 1, query: search });
      } catch (error) {
        console.log(error);
      }
    },
  },

  unmounted() {
    this.SET_COURIER_ID_FILTER(null);
    this.SET_DRIVERS({
      type: "all",
      items: [],
      pages: 0,
      itemsCount: 0,
      page: 1,
    });
    this.SET_ORDERS({
      type: "completed",
      items: [],
      pages: 0,
      itemsCount: 0,
      page: 1,
    });
  },
};
</script>

<style lang="scss" scoped>
.bonus-add {
  &__body {
    padding: 40px 40px 24px 40px;
    border-bottom: 1px solid #f5f5f5;
    color: #37352f;

    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 36px;
      margin-bottom: 40px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-denomination {
      :deep(.input-field__inner) {
        border: 1.5px solid #ebebea;
        border-radius: 12px;
        padding: 6.5px 20px;

        &--error {
          border-color: #da615c;
        }
        input {
          margin: 0;
        }
      }
    }
    textarea {
      width: 100%;
      height: 108px;
      padding: 8px 8px 8px 20px;
      border-radius: 12px;
      border: 1.5px solid #ebebea;
      box-sizing: border-box;
      resize: none;
      outline: none;

      font-size: 14px;
      line-height: 20px;
      color: #37352f;
    }
  }

  &__drivers {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 600;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }

  &__footer {
    padding: 20px 28px 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 156px;
    }
  }
}
</style>
