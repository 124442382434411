import RequestService from "@/api";
const BASE_URL = process.env.VUE_APP_BASE_URL;

const initialState = {
  limit: 15,
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
  orderDetails: null,
  courierIdFilter: null,
  customerIdFilter: null,
  active: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  completed: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  canceled: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  availableDrivers: [],
};

export const state = () => initialState;
export const mutations = {
  SET_ORDERS(state, { type, items, pages, itemsCount, page }) {
    state[type] = {
      items: items,
      pages: pages,
      itemsCount: itemsCount,
      page: page,
    };
  },
  SET_LIMIT(state, payload) {
    state.limit = payload;
  },
  SET_CURRENT_PAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_TOTAL_PAGES(state, payload) {
    state.totalPages = payload;
  },
  SET_TOTAL_ITEMS(state, payload) {
    state.totalItems = payload;
  },
  SET_ORDER_DETAILS(state, payload) {
    state.orderDetails = payload;
  },
  SET_COURIER_ID_FILTER(state, payload) {
    state.courierIdFilter = payload;
  },
  SET_CUSTOMER_ID_FILTER(state, payload) {
    state.customerIdFilter = payload;
  },
  SET_AVAILABLE_ORDER_DRIVERS(state, payload) {
    state.availableDrivers = payload;
  },
};
export const actions = {
  async fetchActiveOrders({ state, commit }, { page, query }) {
    const params = new URLSearchParams();
    params.append("page_size", state.limit);
    params.append("page_number", page);
    params.append("statuses", "published");
    params.append("statuses", "courier_assigned");
    params.append("statuses", "courier_arrived");
    params.append("statuses", "security_code_sent");
    params.append("statuses", "in_delivery");
    params.append("statuses", "delivered");
    if (query) {
      params.append("query", query);
    }
    if (state.courierIdFilter)
      params.append("courier_id", state.courierIdFilter);
    if (state.customerIdFilter)
      params.append("employee_id", state.customerIdFilter);

    const result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/orders/list`,
      params,
    });
    if (page === 1) {
      commit("SET_ORDERS", {
        type: "active",
        items: result.data.orders,
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: page,
      });
    } else {
      commit("SET_ORDERS", {
        type: "active",
        items: [...state["active"].items, ...result.data.orders],
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: page,
      });
    }
  },
  async fetchCompletedOrders({ state, commit }, { page, query }) {
    const params = new URLSearchParams();
    params.append("page_size", state.limit);
    params.append("page_number", page);
    params.append("statuses", "completed");
    if (query) {
      params.append("query", query);
    }
    if (state.courierIdFilter)
      params.append("courier_id", state.courierIdFilter);
    if (state.customerIdFilter)
      params.append("employee_id", state.customerIdFilter);

    const result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/orders/list`,
      params,
    });
    if (page === 1) {
      commit("SET_ORDERS", {
        type: "completed",
        items: result.data.orders,
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: page,
      });
    } else {
      commit("SET_ORDERS", {
        type: "completed",
        items: [...state["completed"].items, ...result.data.orders],
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: page,
      });
    }
  },
  async fetchCanceledOrders({ state, commit }, { page, query }) {
    const params = new URLSearchParams();
    params.append("page_size", state.limit);
    params.append("page_number", page);
    params.append("statuses", "cancelled");
    if (query) {
      params.append("query", query);
    }
    if (state.courierIdFilter)
      params.append("courier_id", state.courierIdFilter);
    if (state.customerIdFilter)
      params.append("employee_id", state.customerIdFilter);

    const result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/orders/list`,
      params,
    });
    if (page === 1) {
      commit("SET_ORDERS", {
        type: "canceled",
        items: result.data.orders,
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: page,
      });
    } else {
      commit("SET_ORDERS", {
        type: "canceled",
        items: [...state["canceled"].items, ...result.data.orders],
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: page,
      });
    }
  },
  async fetchOrderDetailsById({ commit }, { orderId }) {
    const { data } = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/orders/${orderId}`,
    });
    commit("SET_ORDER_DETAILS", { ...data });
  },
  async postCloseOrder({ commit }, { orderId }) {
    const { data } = await RequestService.sendRest({
      method: "post",
      baseURL: BASE_URL,
      url: `/cooperator/courier/support/order/close?order_id=${orderId}`,
    });
  },
  async dropOrderDriver(context, { orderId }) {
    let { data } = await RequestService.sendRest({
      method: "patch",
      baseURL: BASE_URL,
      url: `/cooperator/orders/${orderId}/drop_driver`,
    });

    return data;
  },

  async fetchOrderAvailableDrivers({ commit }, { orderId }) {
    let { data } = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/orders/${orderId}/available_couriers_geo_list`,
    });
    commit("SET_AVAILABLE_ORDER_DRIVERS", data.couriers_geo);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
