<template>
  <div class="input-field">
    <div
      class="input-field__inner"
      :class="{
        'input-field__inner--disabled': disabled,
        'input-field__inner--error': error,
      }"
    >
      <label for="">{{ label }}</label>
      <textarea
        v-model="text"
        :placeholder="placeholder"
        :disabled="disabled"
      />
    </div>
    <span v-if="error && errorText" class="input-field__error-text">{{
      errorText
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Enter Text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: "Field is required",
    },
  },

  data() {
    return {
      text: "",
    };
  },

  watch: {
    modelValue: {
      deep: true,
      immediate: true,
      handler(v) {
        this.text = v;
      },
    },

    text(v) {
      this.$emit("update:modelValue", v);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-field {
  &__inner {
    border: 1.5px solid #ebebea;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 8px 8px 8px 20px;
    position: relative;

    &--error {
      border-color: #da615c;
    }

    &--disabled {
      background: #f5f5f5;
    }
  }

  label {
    font-weight: 500;
    font-size: 12px;
    font-family: Inter, sans-serif;
    line-height: 16px;
    color: #6e6d69;
  }

  textarea {
    width: 100%;
    height: 108px;
    border: none;
    resize: none;
    outline: none;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #37352f;
  }

  &__error-text {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #da615c;
    margin-top: 6px;
    display: block;
  }
}
</style>
