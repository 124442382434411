<template>
  <div class="tracking">
    <h2>Driver search</h2>
    <div class="tracking__map">
      <span v-if="!availableDrivers.length && !loading"
        >No couriers available.</span
      >
      <div ref="map" class="map"></div>

      <div v-if="!loading" class="tracking__actions">
        <button class="button button--white" @click="refresh">
          <img src="@/assets/icons/refresh.svg" alt="" />
        </button>
        <button class="button button--white" @click="setCenter">
          <img src="@/assets/icons/location-2.svg" alt="" />
        </button>
      </div>

      <div v-if="loading" class="tracking__loading">
        <base-loading />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { toRaw } from "vue";
import { Loader } from "@googlemaps/js-api-loader";
import { mapState, mapMutations, mapActions } from "vuex";
import RedIcon from "@/assets/icons/markers/red.svg";
import DriverIcon from "@/assets/icons/markers/driver.svg";
import BaseLoading from "@/components/base/BaseLoading.vue";

export default {
  components: {
    BaseLoading,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loader: null,
      map: null,
      loading: true,
      markers: [],
    };
  },

  computed: {
    ...mapState({
      availableDrivers: (state) => state.orders.availableDrivers,
    }),
    startPoint() {
      return this.data?.departure?.point ?? undefined;
    },
  },

  watch: {
    availableDrivers: {
      deep: true,
      immediate: true,
      handler() {
        if (this.map) {
          this.setAvailableDriversMarkers();
        }
      },
    },

    map: {
      deep: true,
      handler(v) {
        if (v && !this.markers.length && this.availableDrivers.length) {
          this.setAvailableDriversMarkers();
        }
      },
    },
  },

  methods: {
    ...mapMutations({
      resetAvailableDrivers: "orders/SET_AVAILABLE_ORDER_DRIVERS",
    }),
    ...mapActions({
      fetchAvailableDrivers: "orders/fetchOrderAvailableDrivers",
    }),

    async initMap() {
      await this.loader.load();

      this.map = new google.maps.Map(this.$refs.map, {
        zoom: 12,
        center: { lat: 40.771, lng: -73.974 },
        disableDefaultUI: true,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            stylers: [{ visibility: "off" }],
          },
        ],
      });

      const startText = `<h4 class="custom-info custom-info--red">Start</h4>`;
      this.addMarker({
        position: new google.maps.LatLng(
          this.startPoint.lat,
          this.startPoint.lon
        ),
        icon: RedIcon,
        text: startText,
      });

      this.setCenter();
    },
    setCenter() {
      if (this.startPoint)
        this.map.setCenter(
          new google.maps.LatLng(this.startPoint.lat, this.startPoint.lon)
        );
    },
    addMarker({ position, icon, text, setToArr, pixelOffset, driverID }) {
      const marker = new google.maps.Marker({
        position,
        map: this.map,
        icon,
      });

      this.myToolTip({ mark: marker, text, pixelOffset, driverID });

      if (setToArr) this.markers.push(marker);
    },
    deleteMarkers() {
      this.markers?.map((marker) => toRaw(marker).setMap(null));
    },
    myToolTip({ mark, text, pixelOffset, driverID }) {
      const infoWindow = new google.maps.InfoWindow({
        pixelOffset: new google.maps.Size(
          pixelOffset?.x ?? 30,
          pixelOffset?.y ?? 20
        ),
        disableAutoPan: true,
      });

      infoWindow.setContent(text);
      infoWindow.open(this.map, mark);

      if (!driverID) return;
      google.maps.event.addListener(mark, "click", () => {
        if (!mark.open) {
          this.toggleDriverRedirect(driverID);
        }
      });

      // google.maps.event.addListener(mark, "click", function () {
      //   if (!mark.open) {
      //     infoWindow.setContent(text);
      //     infoWindow.open(this.map, mark);
      //     mark.open = true;
      //   } else {
      //     infoWindow.close();
      //     mark.open = false;
      //   }
      //   google.maps.event.addListener(this.map, "click", function () {
      //     infoWindow.close();
      //     mark.open = false;
      //   });
      // });
    },
    async refresh() {
      this.setCenter();
      this.deleteMarkers();
      await this.fetchAvailableDrivers({ orderId: this.$route.params.id });
    },
    setAvailableDriversMarkers() {
      this.availableDrivers.forEach((m) => {
        const contentString = `
        <h4 class="custom-info">${m.full_name}</h4>
        <p id="courier-${m.courier_id}" class="custom-link" onClick="goToDriver('${m.courier_id}')">View ptofile</p>`;
        this.addMarker({
          position: new google.maps.LatLng(m.point.lat, m.point.lon),
          icon: DriverIcon,
          text: contentString,
          pixelOffset: { x: 40, y: 10 },
          setToArr: true,
          driverID: m.courier_id,
        });
      });
    },
    goToDriver(driverID) {
      this.$emit("close");
      this.$router.push({
        name: "DriversDetails",
        params: {
          id: driverID,
        },
      });
    },
    toggleDriverRedirect(driverID) {
      const element = document.getElementById(`courier-${driverID}`);
      element.classList.toggle("mystyle");
    },
  },

  created() {
    this.loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAP_KEY,
      libraries: ["places"],
    });
    this.initMap();
  },

  async mounted() {
    try {
      await this.fetchAvailableDrivers({ orderId: this.$route.params.id });
      window.goToDriver = this.goToDriver;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },

  unmounted() {
    this.resetAvailableDrivers([]);
  },
};
</script>

<style lang="scss" scoped>
.tracking {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;

  h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
  }

  &__map {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    span {
      color: #da615c;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  &__actions {
    position: absolute;
    right: 12px;
    bottom: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__loading {
    position: absolute;
    inset: 0;
    z-index: 10;
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background-color: #f5f5f5;
  }

  .map {
    width: 684px;
    height: 439px;
    box-shadow: 0px 12px 40px -10px rgba(15, 15, 15, 0.07);
    border-radius: 16px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    border-radius: 50%;
    border: 1px solid #f5f5f5;
    box-shadow: 0px 12px 40px -10px rgba(49, 62, 86, 0.1);
    img {
      width: 22px;
      height: 22px;
    }
  }
}
</style>

<style lang="scss">
.gm-style {
  .gm-ui-hover-effect {
    display: none !important;
  }
  .gm-style-iw-tc::after {
    height: 0 !important;
  }

  .gm-style-iw-d,
  .gm-style-iw {
    overflow: hidden !important;
    padding: 0 !important;
  }

  .gm-style-iw-c {
    border-radius: 16px !important;
  }

  .custom-info {
    padding: 10px 12px !important;
    background-color: #37352f;
    color: #fff;
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    &--red {
      background-color: #da615c;
    }
  }

  .custom-link {
    display: none;
    padding: 4px 12px 10px 12px !important;
    background-color: #37352f;
    color: #00A8C3;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .mystyle {
    display: block;
  }
}
</style>
