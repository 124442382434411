import RequestService from "@/api";

import { ACCESS_TOKEN, USER_ID } from "@/constants";
const BASE_URL = process.env.VUE_APP_BASE_URL;

const initialState = {
  logged: !!localStorage.getItem(ACCESS_TOKEN),
  token: JSON.parse(localStorage.getItem(ACCESS_TOKEN)),
  userId: localStorage.getItem(USER_ID),
  username: "",
  usernameSent: false,
  requestKey: "",
};

export const state = () => initialState;

export const getters = {
  logined(state) {
    return state.logged;
  },
};

export const mutations = {
  setToken(state, token) {
    state.token = token;
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(token));
  },
  setLogged(state, value) {
    state.logged = value;
  },
  logout(state) {
    localStorage.removeItem(ACCESS_TOKEN);
    state.logged = false;
    state.token = null;
  },
  setUsernameSent(state, value) {
    state.usernameSent = value;
  },
  setUsername(state, value) {
    state.username = value;
  },
  setRequestKey(state, value) {
    state.requestKey = value;
  },
};

export const actions = {
  async getOtpPassword({ commit }, { username }) {
    await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/auth/otp/${username}`,
      params: {
        user_group: "cooperator",
        assert_customer_company: "legal",
      },
    });
  },
  async getOtpPasswordForRegister({ commit }, { username }) {
    await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/auth/otp/${username}/register`,
      params: {
        user_group: "cooperator",
      },
    });
  },
  async loginByOtpPassword({ state }, { code }) {
    let result = await RequestService.sendRest({
      method: "post",
      baseURL: BASE_URL,
      url: "/auth/otp",
      data: {
        username: state.username,
        password: code,
      },
      params: {
        user_group: "cooperator",
      },
    });
    console.log(state, code);
    return result;
  },
  async registerByOtpPassword({ state }, { code }) {
    let result = await RequestService.sendRest({
      method: "post",
      baseURL: BASE_URL,
      url: "/users",
      data: {
        username: state.username,
        otp_password: code,
        role: "customer",
      },
    });
    console.log(state, code);
    return result;
  },
  async refresh({ state }) {
    let result = await RequestService.sendRest({
      method: "post",
      baseURL: BASE_URL,
      url: "/auth/refresh",
      data: {
        refresh_token: state.token.refresh_token,
        user_id: localStorage.getItem(USER_ID),
      },
    });
    return result;
  },
  logout({ commit }) {
    commit("logout");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
