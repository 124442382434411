<template>
  <div class="input-field">
    <div
      class="input-field__inner"
      :class="{
        'input-field__inner--disabled': disabled,
        'input-field__inner--error': error,
      }"
    >
      <label for="">{{ label }}</label>
      <input
        v-maska
        data-maska="+1 (###) ###-####"
        :disabled="disabled"
        v-model.lazy="phone"
        type="tel"
        :placeholder="placeholder"
        @keypress="isNumber"
        @maska="onMaska"
      />
    </div>
    <span v-if="error && errorText" class="input-field__error-text">{{
      errorText
    }}</span>
  </div>
</template>

<script>
import { vMaska } from "maska";

export default {
  emits: ["update:modelValue"],
  directives: { maska: vMaska },
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Enter Text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: "Field is required",
    },
  },

  data() {
    return {
      phone: "",
    };
  },

  watch: {
    modelValue: {
      handler(value) {
        this.phone = value;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    isNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault();
    },
    onMaska(event) {
      this.$emit("update:modelValue", event.detail.unmasked);
    },
  },
};
</script>

<style scoped lang="scss">
.input-field {
  &__inner {
    border: 1.5px solid #ebebea;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 6.5px 20px;
    position: relative;

    &--error {
      border-color: #da615c;
    }

    &--disabled {
      background: #f5f5f5;
    }
  }

  label {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #6e6d69;
  }

  input {
    font-family: Inter, sans-serif;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #37352f;

    &:focus {
      outline: none;
    }
  }

  &__check {
    position: absolute;
    top: 50%;
    right: 0px;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__error-text {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #da615c;
    margin-top: 6px;
    display: block;
  }
}
</style>
