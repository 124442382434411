import RequestService from "@/api";
const BASE_URL = process.env.VUE_APP_BASE_URL;

const initialState = {
  customerDetail: null,
  customers: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  limit: 20,
};

export const state = () => initialState;

export const mutations = {
  SET_CUSTOMER_DETAIL(state, data) {
    state.customerDetail = data;
  },
  SET_USERS(state, { type, items, pages, itemsCount, page }) {
    state[type] = {
      items: items,
      pages: pages,
      itemsCount: itemsCount,
      page: page,
    };
  },
};

export const actions = {
  async fetchCustomerDetail({ state, commit }, id) {
    const result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/customer/search/${id}`,
    });
    commit("SET_CUSTOMER_DETAIL", result.data);
  },
  async fetchCustomers({ state, commit }, { page, query, sortBy }) {
    const params = new URLSearchParams();
    params.append("page_size", state.limit);
    params.append("page_number", page);

    if (query) {
      params.append("query", query);
    }

    if (sortBy) {
      params.append("sort_by", sortBy);
    }

    const result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/customer/search/list`,
      params,
    });

    if (page === 1) {
      commit("SET_USERS", {
        type: "customers",
        items: result.data.employees,
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: page,
      });
    } else {
      commit("SET_USERS", {
        type: "customers",
        items: [...state["customers"].items, ...result.data.employees],
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: page,
      });
    }
  },
  async updateCustomer({ commit }, { payload, cust_id }) {
    try {
      await RequestService.sendRest({
        method: 'put',
        baseURL: BASE_URL,
        url: `/cooperator/customer/edit_customer/${cust_id}`,
        data: payload,
      });
    } catch (e) {
      console.log(e);
    }
  }

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
