<template>
  <div class="decline-action">
    <div class="decline-action__body">
      <h2>Decline request</h2>
      <textarea
        v-model="reason"
        placeholder="Decline reason..."
        class="decline-action__text"
      ></textarea>
    </div>
    <div class="decline-action__footer">
      <button class="decline-action__cancel" @click="() => $emit('close')">
        Cancel
      </button>
      <button
        :disabled="reason === ''"
        class="button button--primary"
        @click="submitHandler"
      >
        Decline
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubmitAction",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reason: "",
    };
  },
  methods: {
    submitHandler() {
      try {
        this.data.callback(this.reason);
      } finally {
        this.$emit("close");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.decline-action {
  max-width: 400px;
  &__body {
    padding: 40px;
    border-bottom: 1px solid #f5f5f5;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #37352f;
    margin-bottom: 20px;
  }

  &__text {
    width: 100%;
    height: 80px;
    border: none;
    border-bottom: 1.5px solid #ebebea;
    outline: none;

    font-size: 14px;
    line-height: 20px;
    color: #37352f;
  }

  &__footer {
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__cancel {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #00A8C3;
    background: transparent;
    border: none;
    cursor: pointer;
  }
}
</style>
