<template>
  <aside class="sidebar">
    <div class="sidebar__header">
      <img src="@/assets/images/yoway_logo.png" />
      <span v-if="isDemoServer">Demo</span>
    </div>
    <profile-user-card class="sidebar__profile"></profile-user-card>
    <div class="sidebar__menu-wrapper">
      <ul class="sidebar__menu sidebar__menu--main">
        <li>
          <router-link
            :to="'/orders'"
            class="sidebar__menu-link"
            active-class="sidebar__menu-link--active"
          >
            <div class="sidebar__menu-link-inner">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.1 6.94C20.1 7.48 19.81 7.97 19.35 8.22L17.61 9.16L16.13 9.95L13.06 11.61C12.73 11.79 12.37 11.88 12 11.88C11.63 11.88 11.27 11.79 10.94 11.61L4.65002 8.22C4.19002 7.97 3.90002 7.48 3.90002 6.94C3.90002 6.4 4.19002 5.91 4.65002 5.66L6.62002 4.6L8.19002 3.75L10.94 2.27C11.6 1.91 12.4 1.91 13.06 2.27L19.35 5.66C19.81 5.91 20.1 6.4 20.1 6.94Z"
                  fill="white"
                />
                <path
                  d="M9.89997 12.7901L4.04997 9.87014C3.59997 9.64014 3.07997 9.67014 2.64997 9.93014C2.21997 10.1901 1.96997 10.6501 1.96997 11.1501V16.6801C1.96997 17.6401 2.49997 18.5001 3.35997 18.9301L9.20997 21.8501C9.40997 21.9501 9.62997 22.0001 9.84997 22.0001C10.11 22.0001 10.37 21.9301 10.6 21.7801C11.03 21.5201 11.28 21.0601 11.28 20.5601V15.0301C11.29 14.0801 10.76 13.2201 9.89997 12.7901Z"
                  fill="white"
                />
                <path
                  d="M22.03 11.1496V16.6796C22.03 17.6296 21.5 18.4896 20.64 18.9196L14.79 21.8496C14.59 21.9496 14.37 21.9996 14.15 21.9996C13.89 21.9996 13.63 21.9296 13.39 21.7796C12.97 21.5196 12.71 21.0596 12.71 20.5596V15.0396C12.71 14.0796 13.24 13.2196 14.1 12.7896L16.25 11.7196L17.75 10.9696L19.95 9.86964C20.4 9.63964 20.92 9.65964 21.35 9.92964C21.77 10.1896 22.03 10.6496 22.03 11.1496Z"
                  fill="white"
                />
              </svg>

              <span>Orders</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link
            :to="'/couriers'"
            class="sidebar__menu-link"
            active-class="sidebar__menu-link--active"
            :class="{
              'sidebar__menu-link--active': pageName === 'CourierView',
            }"
          >
            <div class="sidebar__menu-link-inner">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
                  fill="#605F5D"
                />
                <path
                  d="M14.08 14.1499C11.29 12.2899 6.73996 12.2899 3.92996 14.1499C2.65996 14.9999 1.95996 16.1499 1.95996 17.3799C1.95996 18.6099 2.65996 19.7499 3.91996 20.5899C5.31996 21.5299 7.15996 21.9999 8.99996 21.9999C10.84 21.9999 12.68 21.5299 14.08 20.5899C15.34 19.7399 16.04 18.5999 16.04 17.3599C16.03 16.1299 15.34 14.9899 14.08 14.1499Z"
                  fill="#605F5D"
                />
                <path
                  d="M19.9904 7.3401C20.1504 9.2801 18.7704 10.9801 16.8604 11.2101C16.8504 11.2101 16.8504 11.2101 16.8404 11.2101H16.8104C16.7504 11.2101 16.6904 11.2101 16.6404 11.2301C15.6704 11.2801 14.7804 10.9701 14.1104 10.4001C15.1404 9.4801 15.7304 8.1001 15.6104 6.6001C15.5404 5.7901 15.2604 5.0501 14.8404 4.4201C15.2204 4.2301 15.6604 4.1101 16.1104 4.0701C18.0704 3.9001 19.8204 5.3601 19.9904 7.3401Z"
                  fill="#605F5D"
                />
                <path
                  d="M21.9902 16.5904C21.9102 17.5604 21.2902 18.4004 20.2502 18.9704C19.2502 19.5204 17.9902 19.7804 16.7402 19.7504C17.4602 19.1004 17.8802 18.2904 17.9602 17.4304C18.0602 16.1904 17.4702 15.0004 16.2902 14.0504C15.6202 13.5204 14.8402 13.1004 13.9902 12.7904C16.2002 12.1504 18.9802 12.5804 20.6902 13.9604C21.6102 14.7004 22.0802 15.6304 21.9902 16.5904Z"
                  fill="#605F5D"
                />
              </svg>

              <span>Gig workers</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link
            :to="'/drivers'"
            class="sidebar__menu-link"
            active-class="sidebar__menu-link--active"
            :class="{
              'sidebar__menu-link--active': pageName === 'DriversView',
            }"
          >
            <div class="sidebar__menu-link-inner">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.75 8.00008C21.75 8.41008 21.41 8.75008 21 8.75008H3C2.59 8.75008 2.25 8.41008 2.25 8.00008C2.25 7.59008 2.59 7.25008 3 7.25008H4.02L4.4 5.44008C4.76 3.69008 5.51 2.08008 8.49 2.08008H15.51C18.49 2.08008 19.24 3.69008 19.6 5.44008L19.98 7.25008H21C21.41 7.25008 21.75 7.59008 21.75 8.00008Z"
                  fill="#605F5D"
                />
                <path
                  d="M22.1816 13.66C22.0316 12.01 21.5915 10.25 18.3815 10.25H5.62155C2.41155 10.25 1.98155 12.01 1.82155 13.66L1.26155 19.75C1.19155 20.51 1.44155 21.27 1.96155 21.84C2.49155 22.42 3.24155 22.75 4.04155 22.75H5.92155C7.54155 22.75 7.85155 21.82 8.05155 21.21L8.25155 20.61C8.48155 19.92 8.54155 19.75 9.44155 19.75H14.5616C15.4616 19.75 15.4916 19.85 15.7516 20.61L15.9516 21.21C16.1516 21.82 16.4616 22.75 18.0816 22.75H19.9615C20.7516 22.75 21.5116 22.42 22.0416 21.84C22.5616 21.27 22.8116 20.51 22.7416 19.75L22.1816 13.66ZM9.00155 15.75H6.00155C5.59155 15.75 5.25155 15.41 5.25155 15C5.25155 14.59 5.59155 14.25 6.00155 14.25H9.00155C9.41155 14.25 9.75155 14.59 9.75155 15C9.75155 15.41 9.41155 15.75 9.00155 15.75ZM18.0016 15.75H15.0016C14.5916 15.75 14.2516 15.41 14.2516 15C14.2516 14.59 14.5916 14.25 15.0016 14.25H18.0016C18.4116 14.25 18.7516 14.59 18.7516 15C18.7516 15.41 18.4116 15.75 18.0016 15.75Z"
                  fill="#605F5D"
                />
              </svg>
              <span>Drivers</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link
            :to="'/coupons'"
            class="sidebar__menu-link"
            active-class="sidebar__menu-link--active"
          >
            <div class="sidebar__menu-link-inner">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.3 10.84C21.69 10.84 22 10.53 22 10.14V9.20999C22 5.10999 20.75 3.85999 16.65 3.85999H7.35C3.25 3.85999 2 5.10999 2 9.20999V9.67999C2 10.07 2.31 10.38 2.7 10.38C3.6 10.38 4.33 11.11 4.33 12.01C4.33 12.91 3.6 13.63 2.7 13.63C2.31 13.63 2 13.94 2 14.33V14.8C2 18.9 3.25 20.15 7.35 20.15H16.65C20.75 20.15 22 18.9 22 14.8C22 14.41 21.69 14.1 21.3 14.1C20.4 14.1 19.67 13.37 19.67 12.47C19.67 11.57 20.4 10.84 21.3 10.84ZM9 8.87999C9.55 8.87999 10 9.32999 10 9.87999C10 10.43 9.56 10.88 9 10.88C8.45 10.88 8 10.43 8 9.87999C8 9.32999 8.44 8.87999 9 8.87999ZM15 15.88C14.44 15.88 13.99 15.43 13.99 14.88C13.99 14.33 14.44 13.88 14.99 13.88C15.54 13.88 15.99 14.33 15.99 14.88C15.99 15.43 15.56 15.88 15 15.88ZM15.9 9.47999L9.17 16.21C9.02 16.36 8.83 16.43 8.64 16.43C8.45 16.43 8.26 16.36 8.11 16.21C7.82 15.92 7.82 15.44 8.11 15.15L14.84 8.41999C15.13 8.12999 15.61 8.12999 15.9 8.41999C16.19 8.70999 16.19 9.18999 15.9 9.47999Z"
                  fill="#292D32"
                />
              </svg>

              <span>Coupons</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link
            :to="'/new-cooperator'"
            class="sidebar__menu-link"
            active-class="sidebar__menu-link--active"
          >
            <div class="sidebar__menu-link-inner">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
                  fill="#605F5D"
                />
                <path
                  d="M14.08 14.1499C11.29 12.2899 6.73996 12.2899 3.92996 14.1499C2.65996 14.9999 1.95996 16.1499 1.95996 17.3799C1.95996 18.6099 2.65996 19.7499 3.91996 20.5899C5.31996 21.5299 7.15996 21.9999 8.99996 21.9999C10.84 21.9999 12.68 21.5299 14.08 20.5899C15.34 19.7399 16.04 18.5999 16.04 17.3599C16.03 16.1299 15.34 14.9899 14.08 14.1499Z"
                  fill="#605F5D"
                />
                <path
                  d="M19.9904 7.3401C20.1504 9.2801 18.7704 10.9801 16.8604 11.2101C16.8504 11.2101 16.8504 11.2101 16.8404 11.2101H16.8104C16.7504 11.2101 16.6904 11.2101 16.6404 11.2301C15.6704 11.2801 14.7804 10.9701 14.1104 10.4001C15.1404 9.4801 15.7304 8.1001 15.6104 6.6001C15.5404 5.7901 15.2604 5.0501 14.8404 4.4201C15.2204 4.2301 15.6604 4.1101 16.1104 4.0701C18.0704 3.9001 19.8204 5.3601 19.9904 7.3401Z"
                  fill="#605F5D"
                />
                <path
                  d="M21.9902 16.5904C21.9102 17.5604 21.2902 18.4004 20.2502 18.9704C19.2502 19.5204 17.9902 19.7804 16.7402 19.7504C17.4602 19.1004 17.8802 18.2904 17.9602 17.4304C18.0602 16.1904 17.4702 15.0004 16.2902 14.0504C15.6202 13.5204 14.8402 13.1004 13.9902 12.7904C16.2002 12.1504 18.9802 12.5804 20.6902 13.9604C21.6102 14.7004 22.0802 15.6304 21.9902 16.5904Z"
                  fill="#605F5D"
                />
              </svg>

              <span>New Cooperator</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link
            :to="'/customers'"
            class="sidebar__menu-link"
            active-class="sidebar__menu-link--active"
          >
            <div class="sidebar__menu-link-inner">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.5291 7.77C17.4591 7.76 17.3891 7.76 17.3191 7.77C15.7691 7.72 14.5391 6.45 14.5391 4.89C14.5391 3.3 15.8291 2 17.4291 2C19.0191 2 20.3191 3.29 20.3191 4.89C20.3091 6.45 19.0791 7.72 17.5291 7.77Z"
                  fill="#605F5D"
                />
                <path
                  d="M20.7916 14.6999C19.6716 15.4499 18.1016 15.7299 16.6516 15.5399C17.0316 14.7199 17.2316 13.8099 17.2416 12.8499C17.2416 11.8499 17.0216 10.8999 16.6016 10.0699C18.0816 9.86991 19.6516 10.1499 20.7816 10.8999C22.3616 11.9399 22.3616 13.6499 20.7916 14.6999Z"
                  fill="#605F5D"
                />
                <path
                  d="M6.44016 7.77C6.51016 7.76 6.58016 7.76 6.65016 7.77C8.20016 7.72 9.43016 6.45 9.43016 4.89C9.43016 3.29 8.14016 2 6.54016 2C4.95016 2 3.66016 3.29 3.66016 4.89C3.66016 6.45 4.89016 7.72 6.44016 7.77Z"
                  fill="#605F5D"
                />
                <path
                  d="M6.55109 12.8501C6.55109 13.8201 6.76109 14.7401 7.14109 15.5701C5.73109 15.7201 4.26109 15.4201 3.18109 14.7101C1.60109 13.6601 1.60109 11.9501 3.18109 10.9001C4.25109 10.1801 5.76109 9.8901 7.18109 10.0501C6.77109 10.8901 6.55109 11.8401 6.55109 12.8501Z"
                  fill="#605F5D"
                />
                <path
                  d="M12.1208 15.87C12.0408 15.86 11.9508 15.86 11.8608 15.87C10.0208 15.81 8.55078 14.3 8.55078 12.44C8.56078 10.54 10.0908 9 12.0008 9C13.9008 9 15.4408 10.54 15.4408 12.44C15.4308 14.3 13.9708 15.81 12.1208 15.87Z"
                  fill="#605F5D"
                />
                <path
                  d="M8.87078 17.9399C7.36078 18.9499 7.36078 20.6099 8.87078 21.6099C10.5908 22.7599 13.4108 22.7599 15.1308 21.6099C16.6408 20.5999 16.6408 18.9399 15.1308 17.9399C13.4208 16.7899 10.6008 16.7899 8.87078 17.9399Z"
                  fill="#605F5D"
                />
              </svg>

              <span>Customers</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link
            :to="'/driver-bonus'"
            class="sidebar__menu-link"
            active-class="sidebar__menu-link--active"
          >
            <div class="sidebar__menu-link-inner">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19.8501 12.9401C19.8501 13.7401 20.5001 14.4001 21.3001 14.4001C21.6801 14.4001 22.0001 14.7101 22.0001 15.0901C22.0001 18.9301 20.8401 20.0901 17.0001 20.0901H11.7501V18.5001C11.7501 18.1101 11.4501 17.7901 11.0701 17.7601C11.0501 17.7501 11.0201 17.7501 11.0001 17.7501C10.5901 17.7501 10.2501 18.0901 10.2501 18.5001V20.0901H8.49009C6.61009 20.0901 5.64009 18.6801 4.76009 16.5501L4.59009 16.1301C4.45009 15.7701 4.62009 15.3601 4.98009 15.2201C5.35009 15.0801 5.64009 14.7901 5.79009 14.4101C5.95009 14.0401 5.95009 13.6301 5.80009 13.2601C5.48009 12.4901 4.60009 12.1201 3.82009 12.4301C3.65009 12.5101 3.45009 12.5101 3.28009 12.4301C3.11009 12.3601 2.98009 12.2201 2.90009 12.0401L2.75009 11.6401C1.26009 8.02006 1.91009 6.47006 5.53009 4.97006L7.98009 3.96006C8.34009 3.81006 8.75009 3.98006 8.89009 4.34006L11.0701 9.09006C10.6601 9.09006 10.2501 9.42006 10.2501 9.83006V14.1701C10.2501 14.5801 10.5901 14.9201 11.0001 14.9201C11.0201 14.9201 11.0501 14.9201 11.0701 14.9101C11.4501 14.8801 11.7501 14.5601 11.7501 14.1701V9.83006C11.7501 9.44006 11.4501 9.12006 11.0701 9.09006V4.93006H17.0001C20.8401 4.93006 22.0001 6.09006 22.0001 9.93006V10.7801C22.0001 11.1701 21.6801 11.4801 21.3001 11.4801C20.5001 11.4801 19.8501 12.1301 19.8501 12.9401Z"
                  fill="white"
                />
              </svg>

              <span>Driver bonus</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link
            :to="'/notifications'"
            class="sidebar__menu-link"
            active-class="sidebar__menu-link--active"
          >
            <div class="sidebar__menu-link-inner">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="vuesax/bold/notification-bing">
                  <g id="Group">
                    <path
                      id="Vector"
                      d="M20.1912 14.0598L19.0612 12.1798C18.8112 11.7698 18.5912 10.9798 18.5912 10.4998V8.6298C18.5912 4.9998 15.6412 2.0498 12.0212 2.0498C8.39118 2.0598 5.44118 4.9998 5.44118 8.6298V10.4898C5.44118 10.9698 5.22118 11.7598 4.98118 12.1698L3.85118 14.0498C3.42118 14.7798 3.32118 15.6098 3.59118 16.3298C3.86118 17.0598 4.47118 17.6398 5.27118 17.8998C6.35118 18.2598 7.44118 18.5198 8.55118 18.7098C8.66118 18.7298 8.77118 18.7398 8.88118 18.7598C9.02118 18.7798 9.17118 18.7998 9.32118 18.8198C9.58118 18.8598 9.84118 18.8898 10.1112 18.9098C10.7412 18.9698 11.3812 18.9998 12.0212 18.9998C12.6512 18.9998 13.2812 18.9698 13.9012 18.9098C14.1312 18.8898 14.3612 18.8698 14.5812 18.8398C14.7612 18.8198 14.9412 18.7998 15.1212 18.7698C15.2312 18.7598 15.3412 18.7398 15.4512 18.7198C16.5712 18.5398 17.6812 18.2598 18.7612 17.8998C19.5312 17.6398 20.1212 17.0598 20.4012 16.3198C20.6812 15.5698 20.6012 14.7498 20.1912 14.0598ZM12.7512 9.9998C12.7512 10.4198 12.4112 10.7598 11.9912 10.7598C11.5712 10.7598 11.2312 10.4198 11.2312 9.9998V6.89981C11.2312 6.4798 11.5712 6.1398 11.9912 6.1398C12.4112 6.1398 12.7512 6.4798 12.7512 6.89981V9.9998Z"
                      fill="white"
                    />
                    <path
                      id="Vector_2"
                      d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z"
                      fill="white"
                    />
                  </g>
                </g>
              </svg>

              <span>Notifications</span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link
            :to="'/application-zones'"
            class="sidebar__menu-link"
            active-class="sidebar__menu-link--active"
          >
            <div class="sidebar__menu-link-inner">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M22 11.25H19.96C19.6 7.44 16.56 4.39 12.75 4.04V2C12.75 1.59 12.41 1.25 12 1.25C11.59 1.25 11.25 1.59 11.25 2V4.04C7.44 4.4 4.39 7.44 4.04 11.25H2C1.59 11.25 1.25 11.59 1.25 12C1.25 12.41 1.59 12.75 2 12.75H4.04C4.4 16.56 7.44 19.61 11.25 19.96V22C11.25 22.41 11.59 22.75 12 22.75C12.41 22.75 12.75 22.41 12.75 22V19.96C16.56 19.6 19.61 16.56 19.96 12.75H22C22.41 12.75 22.75 12.41 22.75 12C22.75 11.59 22.41 11.25 22 11.25ZM12 15.12C10.28 15.12 8.88 13.72 8.88 12C8.88 10.28 10.28 8.88 12 8.88C13.72 8.88 15.12 10.28 15.12 12C15.12 13.72 13.72 15.12 12 15.12Z"
                  fill="white"
                />
              </svg>

              <span>Application Zones</span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>

    <button class="sidebar__logout button button" @click="logout">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="vuesax/outline/logout">
            <g id="logout">
            <path id="Vector" d="M17.44 15.3699C17.25 15.3699 17.06 15.2999 16.91 15.1499C16.62 14.8599 16.62 14.3799 16.91 14.0899L18.94 12.0599L16.91 10.0299C16.62 9.73994 16.62 9.25994 16.91 8.96994C17.2 8.67994 17.68 8.67994 17.97 8.96994L20.53 11.5299C20.82 11.8199 20.82 12.2999 20.53 12.5899L17.97 15.1499C17.82 15.2999 17.63 15.3699 17.44 15.3699Z" fill="#808080"/>
            <path id="Vector_2" d="M19.93 12.8101H9.76001C9.35001 12.8101 9.01001 12.4701 9.01001 12.0601C9.01001 11.6501 9.35001 11.3101 9.76001 11.3101H19.93C20.34 11.3101 20.68 11.6501 20.68 12.0601C20.68 12.4701 20.34 12.8101 19.93 12.8101Z" fill="#808080"/>
            <path id="Vector_3" d="M11.76 20.75C6.61001 20.75 3.01001 17.15 3.01001 12C3.01001 6.85 6.61001 3.25 11.76 3.25C12.17 3.25 12.51 3.59 12.51 4C12.51 4.41 12.17 4.75 11.76 4.75C7.49001 4.75 4.51001 7.73 4.51001 12C4.51001 16.27 7.49001 19.25 11.76 19.25C12.17 19.25 12.51 19.59 12.51 20C12.51 20.41 12.17 20.75 11.76 20.75Z" fill="#808080"/>
            </g>
            </g>
        </svg>

      Logout
    </button>
  </aside>
</template>

<script>
import { mapState } from "vuex";
import ProfileUserCard from "../profile/ProfileUserCard.vue";

export default {
  components: { ProfileUserCard },
  data() {
    return {
      showAddOrder: false,
    };
  },
  computed: {
    ...mapState("profile", ["role", "profile"]),
    pageName() {
      return this.$route.name;
    },
    isDemoServer() {
      return this.profile?.user?.access_server === "demo";
    },
  },
  methods: {
    errorText() {
      this.$notify({
        text: "Development of this page in progress",
        type: "error",
      });
    },
    logout() {
      this.$store.commit("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/silence/scroll-bar-decorative";

.sidebar {
  background: var(--Yoway-Brand-BG-Cream, #F6EBE7);
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  height: 100vh;
  width: 280px;
  transition: 0.3s;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 25px 0 25px;
    margin-bottom: 32px;

    img {
        width: 100px;
        height: 26px;
    }

    span{
      padding: 7px 21px;
      border-radius: 50px;
      background-color: #00A8C3;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &__profile {
    padding-bottom: 24px;
    margin-bottom: 24px;
    margin-left: 16px;
    margin-right: 16px;
  }

  &__menu-wrapper {
    // overflow: auto;
    // @extend %scroll-bar-decorative;
  }

  &__menu {
    margin: 0 16px;
    // overflow-y: auto;
    // overflow-y: auto;
    @extend %scroll-bar-decorative;

    &--main {
      padding-bottom: 24px;
    }

    &--advanced {
      // padding-top: 24px;
    }

    li {
      position: relative;
    }

    &-item {
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &--opened {
        border: 1px solid #e0e2e7 !important;

        .sidebar__menu-more {
          transform: rotate(180deg);
        }
      }
    }

    &-link {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: var(--Yoway-Brand-Gray-1, #4D4D4D);
      font-variant-numeric: lining-nums proportional-nums;
      /* Yoway/Web/Semibold 14 */
      font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      padding: 0 8px;
      border-radius: 12px;
      transition: 0.2s;
      cursor: pointer;

      svg {
        margin-right: 16px;
        path {
          fill: rgba(130, 129, 127, 0.7);
          transition: 0.2s;
        }
      }

      &:not(.sidebar__menu-link--active):hover {
        color: #454545;
        background: #80808033;
        transition: 0.2s;

        svg {
          path {
            fill: #454545;
            transition: 0.2s;
          }
        }
      }

      &--active {
        background: #F6D0CE;
        color: var(--Yoway-Brand-Gray-1, #4D4D4D);
        transition: 0.2s;

        svg {
          path {
            fill: #743D74;
            transition: 0.2s;
          }
        }
      }

      span {
        display: flex;
        margin-right: auto;
        white-space: nowrap;
        margin-right: auto;
      }

      &-inner {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 16px 8px 16px 16px;
      }
    }

    &-more {
      transition: 0.3s;
    }
  }

  &__logout {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    background: transparent;
    margin: 16px 16px 24px 12px;
    margin-top: auto;
    color: var(--Yoway-Brand-Gray-2, #808080);
    font-variant-numeric: lining-nums proportional-nums;

    /* Yoway/Web/Semibold 14 */
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */

    &:hover {
        background: #80808011;
    }
  }
}
</style>
