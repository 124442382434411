import RequestService from "@/api";
const BASE_URL = process.env.VUE_APP_BASE_URL;

const initialState = {};

export const state = () => initialState;

export const getters = {};

export const mutations = {};

export const actions = {
  async createCooperator(context, params) {
    await RequestService.sendRest({
      method: "post",
      baseURL: BASE_URL,
      url: `/system/cooperators/create`,
      data: params,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
