import RequestService from "@/api";
import { BASE_URL } from "@/constants";

const initialState = { isDataSending: false, pageTitle: "", timezone: null };

export const state = () => initialState;

export const getters = {};

export const mutations = {
  setIsDataSending(state, payload) {
    state.isDataSending = payload;
  },
  setPageTitle(state, payload) {
    state.pageTitle = payload;
  },
  setAppTimezone(state, payload) {
    state.timezone = payload;
  },
};

export const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
