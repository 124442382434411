<template>
  <div class="submit-action">
    <div class="submit-action__body">
      <h2>{{ data.message }}</h2>
      <p>
        {{ data.description || "You won't be able to cancel this action." }}
      </p>
    </div>
    <div class="submit-action__footer">
      <button class="submit-action__cancel" @click="() => $emit('close')">
        Cancel
      </button>
      <button class="button button--primary" @click="submitHandler">
        {{ data.buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubmitAction",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    submitHandler() {
      try {
        this.data.callback();
      } finally {
        this.$emit("close");
      }
    },
  },
};
</script>
<style scoped lang="scss">
.submit-action {
  max-width: 400px;
  &__body {
    padding: 40px;
    border-bottom: 1px solid #f5f5f5;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #37352f;
    margin-bottom: 20px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #37352f;

    strong {
      font-weight: bold;
    }
  }

  &__footer {
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__cancel {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #00A8C3;
    background: transparent;
    border: none;
    cursor: pointer;
  }
}
</style>
