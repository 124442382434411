import RequestService from "@/api";
const BASE_URL = process.env.VUE_APP_BASE_URL;

const initialState = {
  items: [],
  pages: 0,
  itemsCount: 0,
  page: 1,
  addNotificationModalOpened: false,
  usersType: null,
  isLoading: false,
};

export const state = () => initialState;

export const mutations = {
  SWITCH_ADD_NOTIFICATION_MODAL_OPENED(state) {
    state.addNotificationModalOpened = !state.addNotificationModalOpened;
  },
  SWITCH_IS_LOADING(state) {
    state.isLoading = !state.isLoading;
  },
  SET_NOTIFICATIONS(state, { items, pages, itemsCount, page }) {
    state.items = items;
    state.pages = pages;
    state.itemsCount = itemsCount;
    state.page = page;
  },
  RESET_STATE(state) {
    state.items = [];
    state.pages = 0;
    state.itemsCount = 0;
    state.page = 1;
  },
  SET_NOTIFICATIONS_USERS_TYPE(state, payload) {
    state.usersType = payload;
  },
};

export const actions = {
  async createNotification(context, params) {
    await RequestService.sendRest({
      method: "post",
      baseURL: BASE_URL,
      url: `/cooperator/notices/${params.user_group}/bulk_notifications/create`,
      data: params,
    });
  },

  async fetchNotifications({ state, commit }, params) {
    const initParams = new URLSearchParams();
    initParams.append("page_number", params.page);
    if (params?.search) {
      initParams.append("search", params.search);
    }
    if (params?.dateFrom) {
      initParams.append("date_from", params.dateFrom);
    }
    if (params?.dateTo) {
      initParams.append("date_to", params.dateTo);
    }
    const result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/notices/${params.user_group}/bulk_notifications/list`,
      params: initParams,
    });
    // commit("SET_NOTIFICATIONS", {
    //   items: [...state.items, ...result.data.bulk_notifications],
    //   pages: result.data.pagination.total_pages,
    //   itemsCount: result.data.pagination.total_items,
    //   page: result.data.pagination.page_number,
    // });
    if (params?.page === 1) {
      commit("SET_NOTIFICATIONS", {
        items: result.data.bulk_notifications,
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: result.data.pagination.page_number,
      });
    } else {
      commit("SET_NOTIFICATIONS", {
        items: [...state.items, ...result.data.bulk_notifications],
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: result.data.pagination.page_number,
      });
    }
  },

  async fetchNotificationById(
    { state, commit },
    { user_group, bulk_notification_id }
  ) {
    const result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/notices/${user_group}/bulk_notifications/${bulk_notification_id}`,
      params: {},
    });
  },

  setNotificationsUsersType({ commit }, payload) {
    commit("SET_NOTIFICATIONS_USERS_TYPE", payload);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
