import { computed } from "vue";
import store from "@/store/index";
import moment from "moment";
require("moment-timezone");

const timezone = computed(() => store.state.app.timezone);

export const displayDate = (date, format = null, change_tz = true) => {
  let formatDate = format ?? "MM/DD/YYYY";
  if (!date) return "not specified";

  if (change_tz) {
    if (timezone.value === null || timezone.value?.isLa) {
      return moment.utc(date).tz("America/Los_Angeles").format(formatDate);
    } else {
      return moment.utc(date).local().format(formatDate);
    }
  } else {
    return moment.utc(date).format(formatDate);
  }
};

export const displayTime = (date) => {
  if (!date) return "not specified";

  if (timezone.value === null || timezone.value?.isLa) {
    return moment.utc(date).tz("America/Los_Angeles").format("hh:mm A");
  } else {
    return moment.utc(date).local().format("hh:mm A");
  }
};

export const setDateWithTimezone = (date) => {
  if (timezone.value === null || timezone.value?.isLa) {
    return moment(date).tz("America/Los_Angeles", true).format();
  }
  return moment.utc(date).local().format();
};
