<template>
  <div class="confirm">
    <div class="confirm__info">
      <h2>Remove the driver from the order?</h2>
      <p>Are you sure you want to remove the driver from the order?
        <br>The order will revert back to the search.</p>
    </div>
    <div class="confirm__actions">
      <button
        class="button button--error"
        @click="onConfirm">
        Yes
      </button>
      <button
        class="button button--primary"
        @click="$emit('close')">
        No
      </button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['close'],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onConfirm() {
      this.data.callback({
        closeModal: () => this.$emit('close'),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.confirm {
  display: flex;
  flex-direction: column;

  &__info {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px 40px 56px 40px;
    border-bottom: 1px solid #f5f5f5;
    color: #37352f;

    h2 {
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;

    .button {
      width: 170px;
      height: 48px;
    }
  }
}
</style>
