import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import DefaultLayout from "@/layouts/DefaultLayout";
import AuthLayout from "@/layouts/AuthLayout";
import Notifications from "@kyvg/vue3-notification";

import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";

import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

import { pageTitle } from "vue-page-title";

const app = createApp(App);
app.component("DefaultLayout", DefaultLayout);
app.component("AuthLayout", AuthLayout);

app.use(router);
app.use(store);
app.use(Notifications);
app.use(VCalendar, {});
app.use(VueTelInput, {
  mode: "international",
  defaultCountry: "US",
  preferredCountries: ["US", "RU", "CA", "CH"],
  inputOptions: {
    showDialCode: true,
  },
});

app.use(
  pageTitle({
    suffix: " - YOWAY",
    mixin: true,
  })
);

app.use(VueViewer, {
  defaultOptions: {
    zIndex: 9999,
    toolbar: false,
    navbar: false,
  },
});

app.mount("#app");
