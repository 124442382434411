<template>
  <el-select
    :disabled="disabled"
    multiple
    collapse-tags
    collapse-tags-tooltip
    v-model="selectedValues"
    class="form-multi-select"
    :placeholder="placeholder"
    size="large"
    filterable
    remote
    reserve-keyword
    :remote-method="remoteMethod"
    popper-class="dropdown"
  >
    <template v-for="(item, index) in drivers" :key="index">
      <div v-if="item.isHeader" class="form-multi-select__list-header">
        {{ item.label }}
      </div>
      <el-option
        v-else
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
        <div class="option-body">
          <label class="custom-checkbox">
            <input
              type="checkbox"
              :checked="selectedValues.includes(item.value)"
            />
            <span class="checkmark"></span>
          </label>
          <img
            v-if="!item.isSender"
            class="option-body__avatar"
            :src="item.avatar"
            alt="avatar"
          />
          <div class="option-body__name">{{ item.label }}</div>
        </div>
      </el-option>
    </template>
  </el-select>
</template>

<script>
import debounce from "lodash/debounce";
import _ from "lodash";

import { ElSelect, ElOption } from "element-plus";
import "element-plus/es/components/select/style/css";
import "element-plus/es/components/option/style/css";

import defaultAvatar from "@/assets/icons/user.svg";

export default {
  components: {
    ElSelect,
    ElOption,
  },
  props: {
    items: {
      type: Array,
    },
    disabled: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      defaultAvatar,
      selectedValues: [],
      onSearch: null,
    };
  },
  watch: {
    selectedValues: {
      deep: true,
      handler(newVal) {
        this.$emit("update:selectedValues", newVal);
      },
    },
    disabled: {
      handler(newVal) {
        if (newVal) {
          this.selectedValues = [];
        }
      },
    },
  },
  computed: {
    drivers() {
      let lastInitial = null;
      let drivers = [];

      this.items.forEach((item) => {
        let currentInitial = item.fullname[0]?.toUpperCase();
        if (currentInitial !== lastInitial) {
          drivers.push({
            isHeader: true,
            label: currentInitial,
          });
          lastInitial = currentInitial;
        }
        drivers.push({
          avatar: item.avatar?.thumb_url || defaultAvatar,
          value: item.user_id,
          label: item.fullname,
          isHeader: false,
          isSender: item.avatar ? false : true,
        });
      });
      return drivers;
    },
  },
  methods: {
    remoteMethod(query) {
      this.onSearch(query);
    },
    loadMore() {
      this.$emit("load-more");
    },
    addScrollEventListener() {
      const dropdown = document.querySelector(
        ".el-select-dropdown .el-scrollbar__wrap"
      );
      if (dropdown) {
        dropdown.addEventListener(
          "scroll",
          _.throttle(this.checkScrollEnd, 200)
        );
      } else {
        setTimeout(this.addScrollEventListener, 300);
      }
    },
    removeScrollEventListener() {
      const dropdown = document.querySelector(
        ".el-select-dropdown .el-scrollbar__wrap"
      );
      if (dropdown) {
        dropdown.removeEventListener(
          "scroll",
          _.throttle(this.checkScrollEnd, 200)
        );
      }
    },
    checkScrollEnd(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        this.loadMore();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.addScrollEventListener();
    });
    this.onSearch = debounce((query) => {
      this.$emit("search", query);
    }, 350);
  },
  //todo destroy eventlisnter
  unmounted() {
    this.removeScrollEventListener();
  },
};
</script>

<style scoped lang="scss">
.form-multi-select {
  &__list-header {
    color: #6e6d69;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px 8px 8px;
    border-bottom: 1px solid #f5f5f5;
  }
}
.option-body {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 40px !important;

  &__checkbox {
    height: 24px;
    width: 24px;
    border-radius: 8px;
    margin-right: 20px;
  }

  &__avatar {
    flex-shrink: 0;
    object-fit: cover;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    margin-right: 12px;
  }

  &__name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.custom-checkbox {
  position: relative;
  display: flex;
  margin-left: 8px;
  margin-right: 20px;

  input {
    margin: 0;
  }
}

.custom-checkbox input[type="checkbox"] {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 1px solid #c3c2c1;
  appearance: none;
  outline: none;
}

.custom-checkbox input[type="checkbox"]:checked {
  background-color: #2461db;
  border: none;
}

.custom-checkbox input[type="checkbox"]:checked::after {
  content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%3E%0A%3Cpath%20d%3D%22M3.27734%207.99991L6.42179%2011.1444L12.7218%204.85547%22%20stroke%3D%22white%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22/%3E%0A%3C/svg%3E");
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
