import RequestService from "@/api";
const BASE_URL = process.env.VUE_APP_BASE_URL;

const initialState = {
  driverDetail: null,
  all: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  active: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  notActive: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  blocked: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  driverReviews: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  deactivated: {
    items: [],
    pages: 0,
    itemsCount: 0,
    page: 1,
  },
  limit: 20,
  activeFilters: {
    active: true,
    not_active: true,
    blocked: true,
    atention_required: true,
    deactivated:true,
  }
};

export const state = () => initialState;

export const mutations = {
  SET_DRIVER_DETAIL(state, data) {
    state.driverDetail = data;
  },
  UPDATE_DRIVER_REVIEW(state, data) {
    state.driverReviews.items = state.driverReviews.items?.map((item) => {
      return item.id === data.id ? { ...item, active: data.active } : { ...item };
    });
  },
  SET_DRIVERS(state, { type, items, pages, itemsCount, page }) {
    // var stateItems = [];
    Object.keys(state.activeFilters).forEach((filter) => {
        if (!state.activeFilters[filter]) {
            items = items.filter(driver => driver.state !== filter)
            // if(filter !== 'deactivated'){
            //   items.forEach((item) => {
            //     if(item.is_deactivated === false){
            //       if(item.state !== filter)
            //         stateItems.push(item)
            //     }else{
            //       stateItems.push(item)
            //     }
            //   }
            // )
            //   items = stateItems
            // }else{
            //   items = state.all.items.filter(driver => driver.is_deactivated !== true)
            // }
        }
    })
    state[type] = {
      items: items,
      pages: pages,
      itemsCount: itemsCount,
      page: page,
    };
  },
  FILTER_DRIVERS(state, filter) {
    var stateItems = [];
      // state.all.items = state.all.items.filter(driver => driver.state !== filter)
      if(filter !== 'deactivated'){
        state.all.items.forEach((item) => {
          if(item.is_deactivated === false){
            if(item.state !== filter)
              stateItems.push(item)
          }else{
            stateItems.push(item)
          }
        }
      )
        state.all.items = stateItems
      }else{
        state.all.items = state.all.items.filter(driver => driver.is_deactivated !== true)
      }
  //     if (state.all.items.some(driver => driver.is_deactivated === true)) {
  //     state.all.items = state.all.items.filter(driver => {
  //       if (filter === 'deactivated') {
  //         return driver.is_deactivated !== true;
  //       } else {
  //         return driver.state !== filter;
  //       }
  //     });
  //   } else {
  //     // state.all.items = state.all.items.filter(driver => driver.is_deactivated === false && driver.state !== filter);
  // }
},
  SET_ACTIVE_FILTER(state, filter) {
    state.activeFilters[filter] = !state.activeFilters[filter]  
  }
};

export const actions = {
  async fetchDriverDetail({ state, commit }, id) {
    const result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/courier/search/${id}`,
    });
    commit("SET_DRIVER_DETAIL", result.data);
  },
  async fetchDrivers(
    { state, commit },
    { page, query, statuses, cars, sortBy, courierState, type }
  ) {
    const params = new URLSearchParams();
    params.append("page_size", state.limit);
    params.append("page_number", page);
    if (query) {
      params.append("query", query);
    }

    if (sortBy) {
      params.append("sort_by", sortBy);
    }

    if (statuses?.length) {
      statuses.forEach((status) => {
        params.append("status", status);
      });
    }

    if (cars?.length) {
      cars.forEach((car) => {
        params.append("car_bodies", car);
      });
    }

    if (courierState) {
      params.append("courier_state", courierState);
    }

    const result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/courier/search/list`,
      params,
    });
    if (page === 1) {
      commit("SET_DRIVERS", {
        type,
        items: result.data.couriers,
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: page,
      });
    } else {
      commit("SET_DRIVERS", {
        type,
        items: [...state[type].items, ...result.data.couriers],
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: page,
      });
    }
  },
  async fetchDriverReviews({ state, commit }, { id, page }) {
    const result = await RequestService.sendRest({
      method: "get",
      baseURL: BASE_URL,
      url: `/cooperator/reviews/list`,
      params: {
        courier_id: id,
        page_size: state.limit,
        page_number: page,
      },
    });
    if (page === 1) {
      commit("SET_DRIVERS", {
        type: "driverReviews",
        items: result.data.items,
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: page,
      });
    } else {
      commit("SET_DRIVERS", {
        type: "driverReviews",
        items: [...state["driverReviews"].items, ...result.data.items],
        pages: result.data.pagination.total_pages,
        itemsCount: result.data.pagination.total_items,
        page: page,
      });
    }
  },
  async toggleDriverReview({ state }, { active, review_id }) {
    await RequestService.sendRest({
      method: "patch",
      baseURL: BASE_URL,
      url: `/cooperator/reviews/${review_id}/toggle`,
      data: {
        active,
      },
    });
  },
  // async changeCouponStatus(context, { couponId, enabled }) {
  //   await RequestService.sendRest({
  //     method: "put",
  //     baseURL: BASE_URL,
  //     url: `cooperator/cooperator/courier/deactivate`,
  //     data: {
  //       enabled,
  //     },
  //   });
  // },
  async changeDriverDeactive(context, { driver_id, is_deactivated }) {
    await RequestService.sendRest({
      method: "put",
      baseURL: BASE_URL,
      url: `cooperator/courier/deactivate`,
      data: {
        is_deactivated,
        driver_id
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
