<template>
  <div class="input-field">
    <div
      class="input-field__inner"
      :class="{ 'input-field__inner--error': error }"
      @click="callback"
      @mouseover="showAlternativePlaceholder = true"
      @mouseleave="showAlternativePlaceholder = false"
    >
      <label>{{ label }}</label>
      <input
        :value="modelValue"
        :placeholder="
          enableAlternativePlaceholder && alternativePlaceholder && showAlternativePlaceholder
            ? alternativePlaceholder
            : placeholder
        "
        :type="type"
        :disabled="disabled"
        ref="inputRef"
        @input="e => $emit('update:modelValue', e.target.value)"
      />

      <img v-if="iconUrl" :src="iconUrl" class="input-field__icon" />
    </div>
    <span v-if="error" class="input-field__error-text">{{ errorText }}</span>
  </div>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'

export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Enter text',
    },
    icon: {
      type: String,
      default: '',
    },
    callback: {
      type: Function,
      required: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: 'Field is required',
    },
    currency: {
      type: Boolean,
      default: false,
    },
    alternativePlaceholder: {
      type: String,
      default: '',
    },
    enableAlternativePlaceholder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAlternativePlaceholder: false,
    }
  },
  setup(props) {
    if (props.currency) {
      const { inputRef } = useCurrencyInput({
        currency: 'USD',
        locale: 'en-US',
      })

      return { inputRef }
    }
  },
  computed: {
    iconUrl() {
      return this.icon ? require(`@/assets/icons/${this.icon}.svg`) : ''
    },
  },
}
</script>

<style scoped lang="scss">
.input-field {
  &__inner {
    display: flex;
    flex-direction: column;
    border-bottom: 1.5px solid #ebebea;
    position: relative;

    &--error {
      border-bottom: 1.5px solid #da615c;
    }
  }

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6e6d69;
  }

  input {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #37352f;
    border: none;
    outline: none;
    margin-bottom: 12px;
    padding-right: 30px;
    padding-left: 0px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:placeholder {
      color: #6e6d69;
    }

    &:disabled {
      background: none;
    }
  }

  &__icon {
    position: absolute;
    right: 0;
    bottom: 18px;
    cursor: pointer;
  }

  &__error-text {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #da615c;
    margin-top: 6px;
  }
}
</style>
