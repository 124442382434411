import { createStore } from "vuex";

import app from "@/store/modules/app";
import auth from "@/store/modules/auth";
import profile from "@/store/modules/profile";
import couriers from "@/store/modules/couriers";
import coupons from "@/store/modules/coupons";
import images from "@/store/modules/images";
import cooperators from "@/store/modules/cooperators";
import orders from "@/store/modules/orders";
import customers from "@/store/modules/customers";
import driverBonus from "@/store/modules/driverBonus";
import notifications from "@/store/modules/notifications";
import drivers from "@/store/modules/drivers";
import applicationZones from "@/store/modules/applicationZones";

const store = createStore({
  modules: {
    app,
    auth,
    profile,
    images,
    couriers,
    coupons,
    cooperators,
    orders,
    customers,
    driverBonus,
    notifications,
    drivers,
    applicationZones,
  },
});

export default store;
