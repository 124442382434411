export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  if (!cleaned) return "-";
  const match = cleaned.match(
    /^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/
  );
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}${match[5]}`;
  }
  return "-";
};
