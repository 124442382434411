<template>
  <div
    class="form-select"
    :class="{
      'form-select--expanded': selectExpanded,
      'form-select--error': error,
    }"
  >
    <label class="form-select__label">{{ label }}</label>
    <div
      class="form-select__placeholder"
      :class="{ 'form-select__placeholder--selected': selectedOption }"
      @click="selectExpanded = !selectExpanded"
    >
      <span v-if="selectedOption">{{ selectedOption.name }}</span>
      <span v-else>{{ placeholder }}</span>
      <img class="form-select__icon" src="@/assets/icons/arrow_gray.svg" />
    </div>

    <div v-if="selectExpanded" class="form-select__list" @click.stop>
      <div
        v-for="option in options"
        :key="option.id"
        class="form-select__list-item"
        @click="select(option.id)"
      >
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
    },
    value: [Number, String],
    label: String,
    placeholder: {
      type: String,
      default: "Select Item",
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: "Field is required",
    },
  },
  data() {
    return {
      selectExpanded: false,
    };
  },
  computed: {
    selectedOption() {
      return this.options.find((option) => option.id === this.value);
    },
  },
  methods: {
    select(option) {
      this.selectExpanded = false;
      this.$emit("input", option);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/silence/scroll-bar-decorative";

.form-select {
  background: #ffffff;
  border: 1.5px solid #ebebea;
  border-radius: 14px;
  display: flex;
  padding: 6.5px 10px 6.5px 20px;
  position: relative;
  cursor: pointer;
  flex-direction: column;

  &--expanded {
    border-radius: 14px 14px 0 0;

    .form-select__icon {
      transform: rotate(180deg);
    }
  }

  &--error {
    border-bottom: 1px solid #dc3545;
  }

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6e6d69;
  }

  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6e6d69;

    &--selected {
      color: #37352f;
    }
  }

  &__select {
    width: 100%;
    height: 45px;
    border: none;
    border-bottom: 1px solid #9a9eab;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
  }

  &__icon {
    width: 18px;
    transition: 0.4s;
  }

  &__list {
    position: absolute;
    width: 100%;
    background: #fff;
    left: 0;
    overflow-y: scroll;
    display: block;
    max-height: 300px;
    z-index: 1;
    top: 100%;
    border: 1px solid #e1e5ed;
    border-radius: 0 0 14px 14px;

    @extend %scroll-bar-decorative;

    &-item {
      padding: 10px 10px;
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #121318;

      &:hover {
        color: #2461db;
      }
    }
  }

  &__error-text {
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #dc3545;
    text-decoration: underline;
    margin-left: 15px;

    i {
      width: 15px;
      height: 15px;
      background: red;
      display: block;
      color: white;
      text-align: center;
      justify-content: center;
      display: flex;
      border-radius: 100%;
      padding: 3px;
      line-height: 11px;
      margin-right: 5px;
    }
  }
}
</style>
