<template>
  <header class="header">
    <h1 class="header__title">{{ pageTitle || $route.meta.title }}</h1>
    <div class="header__info">
      <div
        v-if="
          ['coupons', 'enabledCoupons', 'disabledCoupons'].includes(pageName)
        "
      >
        <button
          class="button button--primary button--bigger"
          @click="SWITCH_ADD_MODAL_OPENED()"
        >
          Add coupon
        </button>
      </div>
      <div v-if="['driverBonus'].includes(pageName)">
        <button
          class="button button--primary button--bigger"
          @click="SWITCH_BONUS_MODAL_OPENED()"
        >
          Add bonus
        </button>
      </div>
      <div
        v-if="
          [
            'notifications',
            'notificationsDrivers',
            'notificationsCustomers',
          ].includes(pageName)
        "
      >
        <button
          class="button button--primary button--bigger"
          @click="SWITCH_ADD_NOTIFICATION_MODAL_OPENED()"
        >
          Add notification
        </button>
      </div>
      <div
        v-if="['applicationZones'].includes(pageName)"
        class="rightside"
      ></div>
    </div>
  </header>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    // NotifList,
    // ProfileNav,
  },
  data() {
    return {
      showAddOrder: false,
    };
  },
  computed: {
    ...mapState("app", ["pageTitle"]),
    pageName() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapMutations("coupons", ["SWITCH_ADD_MODAL_OPENED"]),
    ...mapMutations("notifications", ["SWITCH_ADD_NOTIFICATION_MODAL_OPENED"]),
    ...mapMutations("driverBonus", ["SWITCH_BONUS_MODAL_OPENED"]),
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/config";
.header {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 74px 0 62px;

  &__title {
    color: var(--Yoway-Brand-Dark-gray, #1A1A1A);

    /* Yoway/Web/H2 28 */
    font-family: "Lilita One";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 128.571% */
  }

  &__menu {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.add-button {
  display: flex;
  align-items: center;
  border: none;
  background: #00A8C3;
  border-radius: 10px;
  padding: 0;
  cursor: pointer;
  transition: $transition;

  &:hover {
    background: rgba(48, 102, 177, 1);
  }

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 10px 16px;
  }

  &__icon {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    border-left: 1px solid rgba(15, 15, 15, 0.07);
  }
}

.orders {
  position: relative;
  &__add-menu {
    position: absolute;
    right: 0;
    top: calc(100% + 4px);
    z-index: 10;

    &-enter-active,
    &-leave-active {
      transition: all $popupTransition;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateY(-5px);
    }
  }
}
</style>
