<template>
  <div class="checkr-modal">
    <div class="checkr-modal__body">
      <h2>Check</h2>
      <p>
        Are you sure you want to start the verification process? The amount will
        be debited from checkr according to the established tariff.
      </p>
    </div>

    <div class="checkr-modal__footer">
      <button class="button button--primary" @click="$emit('close')">No</button>
      <button class="button button--ghost" @click="submitHandler">Yes</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async submitHandler() {
      await this.data.callback({
        closeModal: () => this.$emit("close"),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.checkr-modal {
  width: 500px;
  &__body {
    padding: 40px;
    border-bottom: 1px solid #f5f5f5;
    color: #37352f;

    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 36px;
      margin-bottom: 40px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__footer {
    padding: 20px 28px 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 213px;
    }
  }
}
</style>
