import RequestService from "@/api";
import { USER_ID } from "@/constants";
const BASE_URL = process.env.VUE_APP_BASE_URL;

const initialState = {
  profile: [],
  role: "",
  companyType: "",
  cardInfo: null,
  userCards: [],
  preferences: [],
};

export const state = () => initialState;

export const getters = {
  profile: (state) => state.profile,
};

export const mutations = {
  SET_PROFILE(state, payload) {
    state.profile = payload;
    localStorage.setItem(USER_ID, payload.user.user_id);
  },
  SET_CARD_INFO(state, payload) {
    state.cardInfo = payload;
  },
  SET_USER_CARDS(state, payload) {
    state.userCards = payload;
  },
  SET_PREFERENCES(state, payload) {
    state.preferences = payload;
  },
};

export const actions = {
  async fetchProfile({ commit }) {
    try {
      let { data } = await RequestService.sendRest({
        method: "get",
        baseURL: BASE_URL,
        url: `/cooperator/profile/me`,
      });

      commit("SET_PROFILE", data);
    } catch (e) {
      console.log(e);
    }
  },

  async getCardAddInfo({ commit }) {
    try {
      let { data } = await RequestService.sendRest({
        method: "post",
        baseURL: BASE_URL,
        url: `/customer/profile/cards/add`,
      });

      commit("SET_CARD_INFO", data);
    } catch (e) {
      console.log(e);
    }
  },

  async getUserCards({ commit }) {
    try {
      let result = await RequestService.sendRest({
        method: "get",
        baseURL: BASE_URL,
        url: `/customer/profile/cards/list`,
      });

      commit("SET_USER_CARDS", result.data.cards);
    } catch (e) {
      console.log(e);
    }
  },

  async deleteUserCard(context, { pm_id }) {
    await RequestService.sendRest({
      method: "delete",
      baseURL: BASE_URL,
      url: `/customer/profile/cards/${pm_id}/remove`,
    });
  },

  async updateProfile(context, payload) {
    try {
      await RequestService.sendRest({
        method: "put",
        baseURL: BASE_URL,
        url: `/customer/profile/me`,
        data: payload,
      });
    } catch (e) {
      console.log(e);
    }
  },

  async getPreferences({ commit }, payload) {
    try {
      let { data } = await RequestService.sendRest({
        method: "get",
        baseURL: BASE_URL,
        url: `/preferences`,
        data: payload,
      });
      commit("SET_PREFERENCES", data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateNewsletterPreference(context, payload) {
    try {
      await RequestService.sendRest({
        method: "post",
        baseURL: BASE_URL,
        url: `/preferences/news/switch`,
        data: payload,
      });
    } catch (e) {
      console.log(e);
    }
  },

  async updatePushPreference(context, payload) {
    try {
      await RequestService.sendRest({
        method: "post",
        baseURL: BASE_URL,
        url: `/preferences/push/switch`,
        data: payload,
      });
    } catch (e) {
      console.log(e);
    }
  },

  async getChart(context, { aggregation, from_date, to_date }) {
    try {
      const { data } = await RequestService.sendRest({
        method: "get",
        baseURL: BASE_URL,
        url: `/customer/profile/dashboard/orders/chart`,
        params: {
          aggregation,
          from_date,
          to_date,
          only_my: true,
        },
      });
      return { data };
    } catch (e) {
      console.log(e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
