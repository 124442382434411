<template>
  <div class="layout">
    <the-sidebar class="layout__sidebar" />
    <the-header v-if="showHeader" class="layout__header" />
    <main
      class="layout__main"
      :class="{ 'layout__main--noindent': !showHeader }"
    >
      <slot />
    </main>
  </div>
</template>

<script>
import TheSidebar from "@/components/common/TheSidebar";
import TheHeader from "@/components/common/TheHeader";

export default {
  name: "DefaultLayout",
  components: {
    TheSidebar,
    TheHeader,
  },
  computed: {
    showHeader() {
      const page = this.$route.name;

      if (
        page === "profileCards" ||
        page === "profileEdit" ||
        page === "profileSettings" ||
        page === "addOrder" ||
        page === "editOrder" ||
        page === "ordersDetails" ||
        page === "CustomersDetail" ||
        page === "CustomersEdit" ||
        [
          "DriversDetails",
          "DriversDetailsProfile",
          "DriversDetailsReview",
          "applicationDetails",
        ].includes(page)
      )
        return false;
      return true;
    },
  },
};
</script>

<style scope lang="scss">
.layout {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: max-content 1fr;
  grid-template-areas: "sidebar header" "sidebar main";
  max-width: 100%;
  min-height: 100vh;

  &__sidebar {
    grid-area: sidebar;
    align-items: stretch;
    position: sticky;
    max-height: 100vh;
    top: 0;
    display: flex;
    z-index: 3;
  }
  &__header {
    grid-area: header;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 11;
    background: #fff;
  }
  &__main {
    grid-area: main;
    background-color: #fff;
    position: relative;
    padding: 0 62px;
    min-height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    display: flex;
    flex-direction: column;

    &--noindent {
      padding: 0;
    }
  }
}
</style>
