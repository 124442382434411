<template>
  <label class="toggler">
    <input
      :value="modelValue"
      :disabled="disabled"
      type="checkbox"
      @change="() => toggle()"
    />
    <span
      :class="{ 'toggler__slider--checked': modelValue }"
      class="toggler__slider round"
    ></span>
  </label>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      this.$emit("update:modelValue", !this.modelValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.toggler {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 24px;
  margin-bottom: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    // &:checked + .toggler__slider::before {
    //   transform: translateX(51px);
    //   background-color: #da9543;
    // }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: -10;
    right: 0;
    bottom: 0;
    background-color: #e0e2e7;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 6px;
    width: 36px;
    height: 24px;

    &::before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: -30px;
      background-color: #fff;
      transition: 0.4s;
      border-radius: 4px;
      transform: translateX(32px);
      top: 2px;
    }

    &--checked {
      background: #00A8C3;
      &::before {
        transform: translateX(44px);
        background-color: #fff;
      }
    }
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider::before {
  transform: translateX(32px);
}
</style>
