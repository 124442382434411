import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/",
    redirect: "/couriers/new",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/coupons",
    name: "coupons",
    component: () => import("../views/Coupons.vue"),
    redirect: "/coupons/enabled",
    meta: {
      requiredAuth: true,
      title: "Coupons",
    },
    children: [
      {
        path: "enabled",
        name: "enabledCoupons",
        component: () => import("../views/coupons/CouponsEnabled.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "disabled",
        name: "disabledCoupons",
        component: () => import("../views/coupons/CouponsDisabled.vue"),
        meta: {
          requiredAuth: true,
        },
      },
    ],
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("../views/Orders.vue"),
    redirect: "/orders/active",
    meta: {
      requiredAuth: true,
      title: "Orders",
    },
    children: [
      {
        path: "active",
        name: "activeOrders",
        component: () => import("../views/orders/OrdersActive.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "completed",
        name: "completedOrders",
        component: () => import("../views/orders/OrdersCompleted.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "canceled",
        name: "canceledOrders",
        component: () => import("../views/orders/OrdersCanceled.vue"),
        meta: {
          requiredAuth: true,
        },
      },
    ],
  },
  {
    path: "/orders/:id",
    name: "ordersDetails",
    component: () => import("../views/OrderDetails.vue"),
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: "/couriers",
    name: "couriers",
    component: () => import("../views/Couriers.vue"),
    meta: {
      requiredAuth: true,
      title: "Gig workers",
    },
    redirect: "/couriers/new",
    children: [
      {
        path: "new",
        name: "newCourierRequests",
        component: () => import("../views/couriers/NewRequests.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "inprocess",
        name: "inprocessCourierRequests",
        component: () => import("../views/couriers/InprocessRequests.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "approved",
        name: "approvedCourierRequests",
        component: () => import("../views/couriers/ApprovedRequests.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "declined",
        name: "declinedCourierRequests",
        component: () => import("../views/couriers/DeclinedRequests.vue"),
        meta: {
          requiredAuth: true,
        },
      },
    ],
  },
  {
    path: "/couriers/:id",
    name: "CourierView",
    component: () => import("../views/CourierView.vue"),
    meta: {
      requiredAuth: true,
      title: "Gig workers details",
    },
  },
  {
    path: "/new-cooperator",
    name: "newCooperator",
    component: () => import("../views/AddCooperator.vue"),
    meta: {
      requiredAuth: true,
      title: "Add new cooperator",
    },
  },
  {
    path: "/drivers",
    name: "DriversView",
    component: () => import("../views/drivers/Drivers.vue"),
    redirect: "/drivers/all-drivers",
    meta: {
      requiredAuth: true,
      title: "Drivers",
    },
    children: [
      {
        path: "all-drivers",
        name: "DriversAll",
        component: () => import("../views/drivers/DriversAll.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "active",
        name: "DriversActive",
        component: () => import("../views/drivers/DriversActive.vue"),
        meta: {
          requiredAuth: true,
        },
      },
      {
        path: "not-active",
        name: "DriversNotActive",
        component: () => import("../views/drivers/DriversNotActive.vue"),
        meta: {
          requiredAuth: true,
        },
      },
    ],
  },
  {
    path: "/drivers/:id",
    name: "DriversDetails",
    component: () => import("../views/drivers/DriversDetails.vue"),
    redirect: (to) => {
      return to.path + "/profile";
    },
    meta: {
      requiredAuth: true,
      title: "Driver Details",
    },
    children: [
      {
        path: "profile",
        name: "DriversDetailsProfile",
        component: () => import("../views/drivers/DriversDetailsProfile.vue"),
      },
      {
        path: "review",
        name: "DriversDetailsReview",
        component: () => import("../views/drivers/DriversDetailsReview.vue"),
      },
    ],
  },
  {
    path: "/customers",
    name: "Customers",
    component: () => import("../views/Customers.vue"),
    redirect: "/customers/all-customers",
    meta: {
      requiredAuth: true,
      title: "Customers",
    },
    children: [
      {
        path: "all-customers",
        name: "AllCustomers",
        component: () => import("../views/customers/AllCustomers.vue"),
        meta: {
          requiredAuth: true,
        },
      },
    ],
  },
  {
    path: "/customers/:id",
    name: "CustomersDetail",
    component: () => import("../views/customers/CustomersView.vue"),
    meta: {
      requiredAuth: true,
    },
  },{
    path: "/customers-edit/:id",
    name: "CustomersEdit",
    component: () => import("../views/customers/CustomersEdit.vue"),
    meta: {
      requiredAuth: true,
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("../views/Notifications.vue"),
    redirect: "/notifications/drivers",
    meta: {
      requiredAuth: true,
      title: "Notifications",
    },
    children: [
      {
        path: "drivers",
        name: "notificationsDrivers",
        component: () =>
          import("../views/notifications/NotificationsDrivers.vue"),
        meta: {
          requiredAuth: true,
        },
        children: [],
      },
      {
        path: "customers",
        name: "notificationsCustomers",
        component: () =>
          import("../views/notifications/NotificationsCustomers.vue"),
        meta: {
          requiredAuth: true,
        },
        children: [],
      },
    ],
  },
  {
    path: "/notifications/:type/:id",
    name: "notificationDetails",
    component: () => import("../views/notifications/NotificationDetails.vue"),
    meta: {
      requiredAuth: true,
      title: "Notification Details",
    },
  },
  {
    path: "/driver-bonus",
    name: "driverBonus",
    component: () => import("../views/DriverBonus.vue"),
    meta: {
      requiredAuth: true,
      title: "Driver bonus",
    },
  },
  {
    path: "/application-zones",
    name: "applicationZones",
    component: () => import("../views/applicationZones/ApplicationZones.vue"),
    meta: {
      requiredAuth: true,
      title: "Application Zones",
    },
  },
  {
    path: "/application-zones/:id",
    name: "applicationDetails",
    component: () =>
      import("../views/applicationZones/ApplicationZonesDetails.vue"),
    meta: {
      requiredAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (store.getters["auth/logined"] == false) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    if (to.name == "login") {
      if (store.getters["auth/logined"] == true) {
        next({
          path: "/",
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
