<template>
  <div class="user-card">
    <div class="user-card__user">
      <div class="user-card__image"><img src="@/assets/icons/user.svg" /></div>
      <div class="user-card__title">
        <div class="user-card__name">
          {{ profile.cooperator?.name }}
          {{ profile.cooperator?.surname }}
        </div>
        <div class="user-card__phone">{{ profile.user?.username }}</div>
      </div>
    </div>

    <div class="user-card__timezone">
      <span>Local time</span>
      <toggler v-model="isLaTime" />
      <span>LA time</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Toggler from "@/components/form/Toggler.vue";

export default {
  components: {
    Toggler,
  },
  data() {
    return {
      isLaTime: true,
    };
  },
  computed: {
    ...mapState("profile", ["profile"]),
  },
  watch: {
    isLaTime: {
      handler(v) {
        localStorage.setItem("timezoneLA", JSON.stringify({ isLa: v }));
        this.setAppTimezone({ isLa: v });
      },
    },
  },
  methods: {
    ...mapMutations("app", ["setAppTimezone"]),
  },
  created() {
    const timezone = JSON.parse(localStorage.getItem("timezoneLA"));
    if (timezone) this.isLaTime = timezone.isLa;
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/config";
.user-card {
  display: flex;
  flex-direction: column;
  gap: 12px;
  // display: grid;
  // grid-template-columns: auto 1fr auto;
  // align-items: center;
  padding: 0 8px 24px 8px;

  &__bell {
    position: relative;
  }

  &__new {
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: -2px;
    width: 8px;
    height: 8px;
    background: #da615c;
    border: 2px solid #0f0f0f;
  }

  &__user {
    display: flex;
    align-items: center;
    padding: 0 13px 0 8px;
    border-radius: 12px;
    transition: $transition;
    position: relative;

    &--show {
      background: #262625;
    }
  }

  &__title {
  }

  &__image {
    width: 40px;
    height: 40px;
    background: #ebebea;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 8px 8px 0;
  }

  &__name {

    color: var(--Yoway-Brand-Gray-1, #4D4D4D);
    /* Yoway/Web/H3 20 */
    font-family: "Lilita One";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
    margin-bottom: 4px;
  }

  &__phone {
    color: var(--Yoway-Brand-Gray-1, #4D4D4D);
    font-variant-numeric: lining-nums proportional-nums;
    /* Yoway/Web/Semibold 12 */
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
  }

  &__timezone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    color: var(--Yoway-Brand-Gray-1, #4D4D4D);
    font-variant-numeric: lining-nums proportional-nums;

    /* Yoway/Web/Semibold 12 */
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
  }
}
</style>
