<template>
  <div class="coupon-add">
    <div class="coupon-add__body">
      <div class="coupon-add__title">Add coupon</div>
      <div class="coupon-add__form">
        <form-input
          v-model="form.name"
          label="Name"
          placeholder="Enter name"
          :error="v$.form.name.required.$invalid && v$.form.name.$dirty"
        ></form-input>
        <form-input
          v-model="form.code"
          label="Code"
          placeholder="Enter code"
          :error="
            (this.v$.form.code.required.$invalid || this.v$.form.code.alphanumeric.$invalid) 
            && v$.form.code.$dirty"
          :errorText="codeErrorText"  
        ></form-input>
        <form-date
          label="Start date"
          placeholder="Enter start date"
          :modelValue="form.interval.low"
          @update:modelValue="form.interval.low = $event"
          :error="
            v$.form.interval.low.required.$invalid &&
            v$.form.interval.low.$dirty
          "
        ></form-date>
        <form-date
          label="End date"
          placeholder="Enter end date"
          :modelValue="form.interval.high"
          @update:modelValue="form.interval.high = $event"
          :error="
            v$.form.interval.high.required.$invalid &&
            v$.form.interval.high.$dirty
          "
        ></form-date>
        <form-select
          label="Denomination"
          :value="form.denomination.action"
          :options="denominationOptions"
          @input="form.denomination.action = $event"
        ></form-select>
        <form-input
          v-model="form.denomination.value"
          :label="
            form.denomination.action === 'percent' ? 'Value in %' : 'Value in $'
          "
          placeholder="Enter value"
          :error="
            (v$.form.denomination.value.required.$invalid &&
              v$.form.denomination.value.$dirty) ||
            v$.form.denomination.value.maxValue.$invalid
          "
          :errorText="valueErrorText"
        ></form-input>

        <form-input
          v-if="form.denomination.action === 'amount'"
          v-model="form.denomination.lower_bound"
          label="Lower bound ($)"
          placeholder="Enter lower bound"
          :error="
            v$.form.denomination.lower_bound.required.$invalid &&
            v$.form.denomination.lower_bound.$dirty
          "
        ></form-input>
      </div>
    </div>
    <div class="coupon-add__footer">
      <button class="button button--light" @click="$emit('close')">
        Cancel
      </button>
      <button
        class="button button--primary button--bigger"
        @click="send"
        :disabled="isDataSending"
      >
        <div v-if="isDataSending">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Sending...
        </div>
        <span v-else>Create coupon</span>
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";
import { setDateWithTimezone } from "@/helpers/timezoneDateTime";

import FormInput from "../form/FormInput.vue";
import FormDate from "../form/FormDate.vue";
import FormSelect from "../form/FormSelect.vue";

import { required, requiredIf, maxValue } from "@vuelidate/validators";

const alphanumeric = value => {
  const isValid = /^[a-zA-Z0-9]*$/.test(value);
  return isValid ;
};

export default {
  setup: () => ({ v$: useVuelidate({ $lazy: true, $autoDirty: true }) }),
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: { FormInput, FormDate, FormSelect },
  data() {
    return {
      isDataSending: false,
      limit: "",
      denominationOptions: [
        { id: "amount", name: "Amount" },
        { id: "percent", name: "Percent" },
        { id: "priority", name: "Priority boost" },
      ],
      form: {
        code: "",
        name: "",
        interval: {
          low: "",
          high: "",
        },
        denomination: {
          action: "priority",
          value: null,
          lower_bound: null,
        },
        enabled: true,
      },
    };
  },
  computed: {
    codeErrorText() {
      if (this.v$.form.code.required.$invalid) {
        return "Field is required";
      }
      if (this.v$.form.code.alphanumeric.$response !== true) {
        return "Only alphanumeric values are accepted.";
      }
      
      return "";
    },
    valueErrorText() {
      if (this.v$.form.denomination.lower_bound.required.$invalid) {
        return "Field is required";
      }

      if (this.v$.form.denomination.value.maxValue.$invalid) {
        return this.v$.form.denomination.value.maxValue.$message;
      }

      return "";
    },
  },
  watch: {
    "form.denomination.action"(value) {
      if (value === "percent") {
        this.form.denomination.lower_bound = undefined;
        this.$nextTick(() => {
          this.v$.$reset();
        });
      }
    },
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        code: {
          required, alphanumeric,
        },
        interval: {
          low: {
            required,
          },
          high: {
            required,
          },
        },
        denomination: {
          action: {
            required,
          },
          value: {
            required,
            maxValue: maxValue(
              this.form.denomination.action === "percent" ? 99 : 5000
            ),
          },
          lower_bound: {
            required: requiredIf(function () {
              return this.form.denomination.action === "amount";
            }),
          },
        },
      },
    };
  },
  methods: {
    async send() {
      const form = this.form;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.isDataSending = true;
      await this.data.callback({
        closeModal: () => this.$emit("close"),
        form: {
          ...form,
          denomination: {
            action: form.denomination.action,
            value: form.denomination.value * 100,
            lower_bound: form.denomination.lower_bound
              ? form.denomination.lower_bound * 100
              : null,
          },
          interval: {
            low: setDateWithTimezone(form.interval.low),
            high: setDateWithTimezone(form.interval.high),
          },
        },
      });

      this.isDataSending = false;
    },
    sanitizeCode() {
      this.form.code = this.form.code.replace(/[^a-zA-Z0-9]/g, '');
    },
  },
  
  mounted() {
    this.$v = useVuelidate();
  },
};
</script>

<style scoped lang="scss">
.coupon-add {
  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #37352f;
    margin-bottom: 40px;
  }

  &__body {
    padding: 40px;
  }

  &__footer {
    padding: 20px 40px;
    border-top: 1px solid #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // &__cancel {
  //   font-weight: 600;
  //   font-size: 14px;
  //   line-height: 20px;
  //   display: flex;
  //   align-items: center;
  //   color: #6e6d69;
  //   border: none;
  //   background: none;
  //   cursor: pointer;
  // }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
</style>
